.alert {
  background-color: var(--c-orange-10);
  border-bottom-right-radius: 5px;
  border-left: 4px solid var(--c-orange);
  border-top-right-radius: 5px;
  color: var(--c-black);
  font-size: 1.4rem;
  line-height: 1.4rem;
  padding: 0.8rem;
  width: 100%;
}
