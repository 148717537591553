@use '../../base/styles/base/_gf-typography.scss';
@use '../../base/styles/utilities' as u;
@use '../../base/styles/utilities/mixins';

.dashboard {
  flex: 1 1 0%;
  overflow: auto;
  padding: 1.6rem 0 1.6rem 0;
  height: 100%;
  @include mixins.hide-scrollbars;

  &__header {
    width: 100%;
    padding: 1.2rem 2.4rem;
    background-color: var(--gf-neutral-50);
    border-bottom: 1px solid var(--gf-neutral-200);
    border-radius: 0 0 .8rem .8rem;
    display: flex;
    align-items: center;
    gap: 1.6rem;
    flex-shrink: 0;

    .gf-dashboard--detail--header--title {
      display: flex;
      align-items: center;
      gap: 1.2rem;
      flex: 1;

      .input__inline {
        background-color: transparent;

        &:focus {
          color: var(--gf-neutral-400);
        }
      }
    }

    .gf-button--neutral,
    .gf-button--primary {
      padding: .8rem 1.6rem;

      .gf-label--icon-left {
        margin-left: .8rem;
      }

      .gf-label--icon-right {
        margin-right: .8rem;
      }
    }
  }

  & + .dnd-sidebar {
    .dnd-sidebar__node {
      min-height: auto;
    }
  }
}

.palette-colors {
  display: grid;
  flex: 1 1 100%;
  gap: 0.8rem;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);
  width: 100%;
}

.page-controls {
  align-items: center;
  background-color: var(--gf-neutral-50);
  border-radius: 4px;
  border: 1px solid var(--gf-neutral-200);
  bottom: 0;
  display: inline-flex;
  font-size: 1.2rem;
  gap: u.size('sm');
  justify-content: center;
  left: 50%;
  line-height: 0;
  margin: u.size('sm') auto 0 auto;
  padding: u.size('sm');
  position: sticky;
  transform: translateX(-50%);
  width: max-content;

  @include u.shadow;

  .form--no-layout {
    display: inline;
  }

  .input {
    -moz-appearance: textfield;
    appearance: textfield;
    color: inherit;
    font-size: inherit;
    padding: 0.2rem 0;
    text-align: center;
    width: u.size('lg');

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

#dashboard-widget-placeholder:not(:only-child) {
  display: none;
}

// Apply font settings to the Quill editor used in text widgets
.ql-editor p {
  font-size: inherit !important;
  font-family: inherit !important;
}
