@use '../../base/styles/utilities/mixins';

.widgets {
  min-height: fit-content;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 2rem;
  // Vertically aligning the dashboard canvas is causing it to
  // extend into the header - fix this later
  // justify-content: center;
  // height: 100%;

  header {
    display: flex;
    align-items: flex-end;
    align-self: flex-start;
    // height: 6.8rem;
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;

    &:first-of-type {
      align-items: flex-start;
    }
  }

  &__canvas {
    height: auto;
    --background-color: var(--c-white);
    background-color: var(--background-color);
    border: 1px solid var(--c-gray);
    display: inline-block;
    margin: 0 auto;
    // overflow: visible;
    position: relative;
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // align-items: start;

    // @include mixins.shadow;

    #dashboard-widget-container {
      min-height: 100%;
    }
  }

  &__grid {
    background-image: repeating-linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.025),
        rgba(0, 0, 0, 0.025) 1px,
        transparent 1px,
        transparent var(--grid-size)
      ),
      repeating-linear-gradient(
        -90deg,
        rgba(0, 0, 0, 0.025),
        rgba(0, 0, 0, 0.025) 1px,
        transparent 1px,
        transparent var(--grid-size)
      );
    background-position: 1px 1px;
    background-size: var(--grid-size) var(--grid-size);
  }

  #dashboard-widget-placeholder {
    gap: 1.6rem;
    height: auto;
    left: 50%;
    padding: 1.6rem;
    position: absolute;
    top: 42vh;
    transform: translate(-50%, -50%);
    width: max-content;
    color: var(--gf-neutral-600);
  }

  &__error {
    display: flex;
    flex-direction: column;
    flex: 1 1 0%;
    justify-content: center;
    align-items: center;
    padding: 2.5rem;

    i {
      margin-bottom: 0.25rem;
      font-size: 2em;
    }
  }

  &__error-text {
    white-space: normal;
    text-align: center;
  }
}

.metric {
  --metric-title-color: var(--c-black-50);
  --metric-title-size: 1.6rem;

  --metric-value-color: var(--c-black);
  --metric-value-size: 6rem;

  --metric-comparison-color: var(--c-black-50);
  --metric-comparison-size: 3rem;

  align-self: center;
  margin: 0 auto;
  overflow: hidden;
  text-align: center;

  &__title {
    color: var(--metric-title-color);
    font-size: var(--metric-title-size);
    margin-bottom: 0.6rem;
  }

  &__value {
    color: var(--metric-value-color);
    font-size: var(--metric-value-size);
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;

    .empty-icon {
      color: var(--c-black-50);
      margin-bottom: 1rem;
    }

    p {
      font-weight: 400;
    }
  }

  &__comparison {
    color: var(--metric-comparison-color);
    font-size: var(--metric-comparison-size);
  }
}

.widget__public-footer {
  display: flex;
  justify-content: space-between;
  align-items: end;
  width: 100%;
  flex: 1;
}

[data-controller*='widget-visuals-text'] {
  border: none;
  cursor: text;
}

.widgets .ql-container.ql-snow {
  border: none;
}

.widgets .ql-toolbar.ql-snow {
  background: var(--c-white);
  border-bottom: none;
  border: 1px solid var(--c-gray);
  bottom: 100%;
  display: none;
  position: absolute;
  visibility: none;
  width: 100%;
}

.react-draggable[data-focused*='true'] .ql-toolbar.ql-snow {
  display: block;
  visibility: visible;
}

.widgets__export-header {
  align-items: center;
  background-color: var(--c-white);
  border-bottom: 1px solid var(--c-gray);
  display: flex;
  gap: 3.2rem;
  height: 4.2rem;
  left: 0;
  padding: 0.3rem 0.3rem 0.3rem 0.9rem;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: var(--z-modal);

  & + .widgets {
    margin-top: 4.2rem;
  }
}
