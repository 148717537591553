.gf-link {
    color: var(--gf-primary-400);
    text-decoration: none;

    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
}

a {
    @extend .gf-link;
}