@use '../utilities' as u;
@use '../base/gf-typography' as *;

.table {
  border-collapse: separate;
  border-spacing: 0;
  display: table; // Reset just in case another class overrides this.
  height: 100%;
  width: 100%;

  thead {
    position: sticky;
    top: 0;
    width: 100%;

    th {
      border-bottom: 1px solid var(--c-gray);
      background-color: var(--c-gray-50);
      color: var(--c-black);
      font-size: 1.4rem;
      font-weight: normal;
      height: 4.2rem;
      min-width: 12.5%;
      padding: 0 1.6rem;
      text-align: left;
      white-space: nowrap;

      &:last-child {
        display: flex;
        align-items: center;
        width: 100%;
      }
    }

    .column {
      a::before {
        color: var(--c-black-50);
        display: inline;
        font-family: 'Font Awesome 5 Pro';
        font-weight: 900;
        margin-right: 0.6rem;
        position: relative;
      }

      &--numeric a::before {
        content: '\f292';
      }

      &--string a::before {
        content: '\f031';
      }

      &--time a::before {
        content: '\f017';
      }

      &--date a::before {
        content: '\f073';
      }

      &--datetime a::before {
        content: '\f271';
      }

      &--boolean a::before {
        content: '\f058';
      }
      &--dict a::before {
        content: '\f7ea';
      }
      &--array a::before {
        content: '\f7e9';
      }
    }

    .orderable {
      a::after {
        color: var(--c-black-50);
        content: '\f0dc';
        display: inline;
        font-family: 'Font Awesome 5 Pro';
        font-weight: 900;
        margin-left: 0.6rem;
        position: relative;
      }

      &.asc a::after {
        content: '\f0de';
      }

      &.desc a::after {
        content: '\f0dd';
      }
    }
  }

  tfoot {
    bottom: 0;
    position: sticky;
    width: 100%;

    td {
      border-top: 1px solid var(--c-gray);
    }

    &.tfoot--pagination {
      bottom: 3.2rem;
    }
  }

  tbody {
    td {
      @extend .gf-paragraph--xsmall;
      padding: 1.2rem 1.6rem;
      height: 4rem;
      white-space: nowrap;
      border-bottom: 1px solid var(--c-gray-50);

      > * {
        font-size: inherit;
        line-height: inherit;
      }
    }

    tr:hover {
      background-color: var(--c-gray-10);
    }

    tr:last-of-type td {
      border-bottom: 0;
    }
  }

  tfoot {
    tr {
      background-color: var(--c-gray-50);
    }
    td {
      padding: 0 1.6rem;
      font-size: 1.2rem;
      line-height: 1.4rem;
      padding: 0 1.6rem;
      height: 3.9rem;
      font-weight: bold;
    }
  }

  td a {
    color: var(--c-fig-secondary);
    padding: 1.2rem 0;

    &:hover {
      text-decoration: underline;
    }
  }

  .card > .table-container & thead th {
    &:first-child {
      border-top-left-radius: 10px;
    }

    &:last-child {
      width: 100%;
      border-top-right-radius: 10px;
    }
  }

  .hide-header & {
    thead {
      visibility: hidden;
    }

    tbody {
      position: relative;
      top: -4.2rem;
    }
  }
}

.table-data {
  @extend .table;

  thead th {
    padding: 1.2rem 0.8rem;

    & + th {
      border-left: 1px solid var(--c-gray);
    }
  }

  tbody td {
    padding: 0.8rem;

    & + td {
      border-left: 1px solid var(--c-gray);
    }
  }
}

.table-data {
  @extend .table;

  thead th {
    padding: 1.2rem 0.8rem;
    height: auto;

    & + th {
      border-left: 1px solid var(--c-gray);
    }
  }

  tbody td {
    padding: 0.8rem;
    height: auto;

    & + td {
      border-left: 1px solid var(--c-gray);
    }
  }
}

#runs-table-wrapper .table-container {
  height: calc(100vh - 17.2rem);
  overflow-y: auto;

  .table {
    height: auto;
  }
}

.pagination {
  align-items: center;
  background: var(--c-white);
  border-top: 1px solid var(--c-gray);
  bottom: 0;
  display: flex;
  flex-shrink: 0;
  height: 3.2rem;
  justify-content: center;
  left: 0;
  position: sticky;
  width: 100%;

  a {
    padding: u.size('xs') u.size('sm');

    &:hover {
      text-decoration: underline;
      color: var(--c-fig-secondary-50);
    }
  }

  li.active a {
    color: var(--c-fig-secondary);
    font-weight: 500;
  }

  .form--no-layout {
    display: inline;
  }

  .input {
    -moz-appearance: textfield;
    border: 0;
    color: inherit;
    font-size: inherit;
    padding: 0;
    text-align: center;
    width: u.size('lg');

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

.table-container ~ .pad {
  padding: {
    left: 1.6rem;
  }
}

.table__empty {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: 56rem;
  gap: 1.6rem;
  text-align: center;

  &--slim {
    padding: 0;
  }
}

.table__more {
  color: var(--c-black);
  font-size: 1.2rem;
  font-weight: normal;
  line-height: 1.4rem;
  padding: 1.2rem 1.6rem;
  text-align: left;
}

.table .table__action,
.table__action {
  align-items: center;
  background-color: var(--c-transparent);
  border: 1px solid var(--c-gray);
  border-radius: 100%;
  color: var(--c-black-50);
  display: flex;
  font-weight: bold;
  height: u.size('lg');
  justify-content: center;
  padding: 0;
  width: u.size('lg');

  &:hover,
  &:focus {
    background-color: var(--c-fig-secondary);
    border: 1px solid var(--c-transparent);
    color: var(--c-white);
    text-decoration: none;
  }

  &--danger:hover,
  &--danger:focus {
    background-color: var(--c-red);
  }

  &:last-child {
    margin-right: auto;
  }
}
