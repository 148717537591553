@import '../../base/styles/components/_gf-sidebar.scss';

.gf-sidebar--project {
    @extend .gf-sidebar;
    border-top: 1px solid var(--gf-neutral-200);
    border-right: 1px solid var(--gf-neutral-200);
    border-bottom: 1px solid var(--gf-neutral-200);
    border-radius: 0 .8rem .8rem 0;
    transition: 300ms ease;

    &.expanded {
        width: 28rem;
        min-width: 28rem;

        .gf-sidebar--menu-item--label {
            margin-left: 1.6rem;
            opacity: 1;
            visibility: visible;
            flex: 1;
        }
    }

    .gf-sidebar--menu-item--label {
        width: 0;
        min-width: 0;
        margin-left: 0;
        opacity: 0;
        visibility: hidden;
        white-space: nowrap;
        transition: 300ms ease;
    }

    .gf-sidebar--menu-section--bottom {
        position: relative;

        #gf-collapse--sidebar--project {
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }
}