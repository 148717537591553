/*=============================================
=          Go Fig Breakpoints System          =
=============================================*/

$gf-breakpoints: (
    "small": 375px,
    "medium": 1439px,
    "large": 1440px
);

@function get-breakpoint($breakpoint) {
    $value: map-get($gf-breakpoints, $breakpoint);
    @return $value;
}

/*============ Media Query Mixins =============

Usage:
Increase padding on medium and large screens.

.my-element {
    padding: 1rem;

    @include gf-breakpoint-up("medium") {
        padding: 2rem;
    }
    @include gf-breakpoint-only("large") {
        padding: 3rem;
    }
}

*/

@mixin gf-breakpoint-only($breakpoint) {
    @if $breakpoint == "small" {
        @media screen and (max-width: #{get-breakpoint("small")}) {
            @content;
        }
    } @else if $breakpoint == "medium" {
        $min: get-breakpoint("small") + 1;
        $max: get-breakpoint("medium");
        @media screen and (min-width: #{$min}) and (max-width: #{$max}) {
            @content;
        }
    } @else if $breakpoint == "large" {
        @media screen and (min-width: #{get-breakpoint("large")}) {
            @content;
        }
    }
}

@mixin gf-breakpoint-up($breakpoint) {
    @if $breakpoint == "small" {
        @content; // Always apply small styles as base
    } @else {
        $min: if($breakpoint == "medium",
            get-breakpoint("small") + 1,
            get-breakpoint($breakpoint));
        @media screen and (min-width: #{$min}) {
            @content;
        }
    }
}

@mixin gf-breakpoint-down($breakpoint) {
    $max: get-breakpoint($breakpoint);
    @media screen and (max-width: #{$max}) {
        @content;
    }
}


// Original:


/*============== Responsiveness ===============

Breakpoint variables and media query mixins.

Usage:
.my-component {
    padding: 1rem;

    @include small-only {
        padding: 0.5rem;
    }
    @include medium-only {
        padding: 1.5rem;
    }
    @include large-up {
        padding: 2rem;
    }
}
*/

// $breakpoint-small: 375px; // Mobile
// $breakpoint-medium: 1439px; // Tablet
// $breakpoint-large: 1440px; // Desktop

// @mixin small-only {
//     @media screen and (max-width: #{$breakpoint-small}) {
//         @content;
//     }
// }

// @mixin medium-only {
//     @media screen and (min-width: #{$breakpoint-small + 1}) and (max-width: #{$breakpoint-medium}) {
//         @content;
//     }
// }

// @mixin medium-up {
//     @media screen and (min-width: #{$breakpoint-small + 1}) {
//         @content;
//     }
// }

// @mixin medium-down {
//     @media screen and (max-width: #{$breakpoint-medium}) {
//         @content;
//     }
// }

// @mixin large-up {
//     @media screen and (min-width: #{$breakpoint-large}) {
//         @content;
//     }
// }