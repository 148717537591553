/*=============================================
=             Go Fig Color System             =
=============================================*/

/*============== Color Palettes =============*/

$gf-primary-colors: (
  50: #F2F0F3,
  100: #E5E1E8,
  200: #CDC5D3,
  300: #B09DBC,
  400: #8F7599,
  500: #3F2946,
  600: #362139,
  700: #2E1C31,
  800: #251626,
  900: #1D111E
);

$gf-sky-colors: (
  50: #F0F8FF,
  100: #CDE8FE,
  200: #BFE0FD,
  300: #A2D2FC,
  400: #85C4F5,
  500: #76c3f9,
  600: #4E9EE0,
  700: #2D7EC0,
  800: #16629D,
  900: #0F4A76
);

$gf-sap-green-colors: (
  50: #F2F7F2,
  100: #E1ECE1,
  200: #C3D8C3,
  300: #96B696,
  400: #729C72,
  500: #568258,
  600: #456C46,
  700: #375737,
  800: #2C442C,
  900: #233823
);

$gf-viridian-colors: (
  50: #F0F7F4,
  100: #E0EFE9,
  200: #C1E0D4,
  300: #94CCBB,
  400: #71BCA3,
  500: #55AD89,
  600: #448E70,
  700: #367459,
  800: #2A5A45,
  900: #1F4434
);

$gf-neutral-colors: (
  50: #F9FAFB,
  100: #F3F4F6,
  200: #E5E7EB,
  300: #D1D5DB,
  400: #9CA3AF,
  500: #6B7280,
  600: #4B5563,
  700: #374151,
  800: #1F2937,
  900: #111827
);

$gf-success-colors: (
  50: #F0FDF4,
  100: #DCFCE7,
  200: #BBF7D0,
  300: #86EFAC,
  400: #4ADE80,
  500: #22C55E,
  600: #16A34A,
  700: #15803D,
  800: #166534,
  900: #14532D
);

$gf-warning-colors: (
  50: #FFFBEB,
  100: #FEF3C7,
  200: #FDE68A,
  300: #FCD34D,
  400: #FBBF24,
  500: #F59E0B,
  600: #D97706,
  700: #B45309,
  800: #92400E,
  900: #78350F
);

$gf-destructive-colors: (
  50: #FEF2F2,
  100: #FEE2E2,
  200: #FECACA,
  300: #FCA5A5,
  400: #F87171,
  500: #EF4444,
  600: #DC2626,
  700: #B91C1C,
  800: #991B1B,
  900: #7F1D1D
);

$gf-generic-colors: (
  "white": #FFFFFF,
  "black": #000000
);

$color-palettes: (
  "gf-primary": $gf-primary-colors,
  "gf-sky": $gf-sky-colors,
  "gf-sap-green": $gf-sap-green-colors,
  "gf-viridian": $gf-viridian-colors,
  "gf-neutral": $gf-neutral-colors,
  "gf-success": $gf-success-colors,
  "gf-warning": $gf-warning-colors,
  "gf-destructive": $gf-destructive-colors,
  "gf-generic": $gf-generic-colors
);

@function get-color($palette, $tone: null) {
  @if $tone {
    @return map-get(map-get($color-palettes, $palette), $tone);
  }

  @return map-get($color-palettes, $palette);
}

// CSS custom properties for each palette:
// background-color: var(--gf-primary-400);
// color: var(--gf-neutral-50);
// border-color: var(--gf-sky-500);

@mixin generate-color-vars($palette-name, $palette) {
  @each $tone, $value in $palette {
    --#{$palette-name}-#{$tone}: #{$value};
  }
}

:root {
  @each $name, $palette in $color-palettes {
    @include generate-color-vars($name, $palette);
  }
}