.section {
  margin: 1rem;
}
  
.app-card {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  padding: 1rem;
}

@media (min-width: 1024px) {

  .app-card {
    margin-top: 0px;
    padding: 2rem;
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
}

.card-title {
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.625;
  text-align: center;
}

.snapshot svg.arrow {
  width: 25px;
  height: 25px;
  padding: unset;
}

.snapshot .row > div {
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  justify-content: center;
  width: unset;
}

.snapshot-chart-container div {
  width:  100%;
}