// Collapsable element that "drops down" it's contents when toggled.
//
// Note the minimal structure required for this to work here:
// ```
// <div data-controller='collapsable'>
// <button data-action='click->collapsable#toggle'>
//   Toggle Button
// </button>
//
// <div class="collapsable" data-collapsable-target='body'>
//   Collapsable content!
// </div>
// </div>
// ```
.collapsable-component {
  position: relative;

  .cursor-pointer {
    cursor: pointer;
  }

  .cursor-pointer::after {
    content: '\f078';
    display: inline;
    font-family: 'Font Awesome 5 Pro';
    font-weight: 900;
    margin-right: .8rem;
  }

  &.active .cursor-pointer::after {
    content: '\f077';
  }

  .collapsable {
    max-height: 0;
    transition: max-height 0.25s ease;
  }
}
