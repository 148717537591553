@import "_gf-typography.scss";
@import "../components/_gf-scrollbar.scss";

/*=============================================
=           Go Fig Base Stylesheet           =
=============================================*/

:root {
    @include gf-scrollbar();
    box-sizing: border-box;
    font-size: 62.5%; // Pattern matching original - 1rem = 10px
    font-family: "Inter", ui-sans-serif, system-ui, -apple-system,
      BlinkMacSystemFont, Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

// TODO:
// Is this being applied?
// Style scrollbar elements globally
[style*="overflow"],
[class*="overflow"],
[style*="overflow-y"],
[style*="overflow-x"],
[class*="scroll"],
.guide,
.modal,
.drawer {
    @include gf-scrollbar();
}

body {
    @include gf-paragraph--small;
    color: var(--gf-neutral-700);
    font-weight: 400;
    background-color: var(--gf-neutral-50);
    margin: 0;
    padding: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .gf-body--content {
        display: flex;
    }

    .gf-body--main {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex: 1;
    }
}

/*================ Testing ==================*/

.gf-full-width {
    width: 100%;
}

@mixin gf-hidden {
    width: 0;
    min-width: 0;
    opacity: 0;
    visibility: hidden;
}

.gf-hidden {
    @include gf-hidden;
}

@mixin gf-visible {
    width: 100%;
    opacity: 1;
    visibility: visible;
}

.gf-visible {
    @include gf-visible;
}

.gf-avatar {
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
}