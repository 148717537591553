@use "../base/gf-typography";

.gf-chip {
    @include gf-typography.gf-label--small;
    @include gf-typography.gf-font-weight("medium");
    padding: .4rem .8rem;
    border: 1px solid;
    border-radius: 4rem;

    &--primary {
        border-color: var(--gf-color-primary-500);
    }

    &--hover {
        background-color: var(--gf-color-primary-50);
    }

    &--selected {
        background-color: var(--gf-color-primary-100);
    }

    &--disabled {
        border-color: var(--gf-color-primary-300);
    }
}
