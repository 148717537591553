@use "../base/gf-typography";

.gf-banner{
    width: 100%;
    height: 4rem;
    color: var(--gf-generic-white);
    background-color: var(--gf-primary-500);
    display: flex;
    justify-content: center;
    align-items: center;

    > a {
        color: var(--gf-generic-white);
        font-weight: gf-typography.get-font-weight("semibold");
        text-decoration: underline;
    }
}