@use "../../base/styles/layout" as *;
@use "../../base/styles/base/_gf-typography.scss" as *;

.gf-integration--detail {
    @extend .gf-container--full-width;
    height: 100vh;
    flex-direction: column;

    &--wrapper {
        width: 100%;
        height: 100%;
    }

    &--sidebar {
        padding: 1.6rem 2.4rem 1.6rem 0;
        overflow: auto;
        flex-shrink: 0;

        >ul {
            display: flex;
            flex-direction: column;
            gap: .8rem;

            >li {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: .8rem;

                >img {
                    width: 2rem;
                    height: 2rem;
                }
            }
        }

    }

    &--content {
        max-width: calc(100vw - 28.8rem); // Combined width of sidebars + padding
        height: 100%;
        overflow: hidden;

        .gf-integration--detail--wrapper {
            height: 100%;
            display: flex;
            flex-direction: row;
            overflow: auto;
        }
    }

    &--footer {
        >ul {
            @extend .gf-paragraph--xsmall;
            color: var(--gf-neutral-500);
            display: flex;
            gap: 2.4rem;
        }
    }
}

.gf-settings {
    padding: 1.6rem;
    display: flex;
    flex-direction: column;
    gap: 1.6rem;

    &--data-source {
        @extend .gf-settings;
        max-width: 80rem;

        &--description {
            display: flex;
            flex-direction: column;
            gap: 1.2rem;

            >p {
                color: var(--gf-neutral-500);
            }
        }
    }

    &--section {
        padding-top: 1.6rem;
        display: flex;
        flex-direction: column;
        gap: 1.6rem;
        border-top: 1px solid var(--gf-neutral-200);
    }
}

.gf-integration--list--tables {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
}
