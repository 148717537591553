.react-flow {
  background: var(--c-gray-10);
  overflow: visible;
  z-index: var(--z-default);

  .react-flow__node {
    align-items: center;
    background-color: var(--c-white);
    border-radius: 10px;
    border: 2px solid var(--c-gray-20);
    box-shadow: 4px 10px 30px rgba(0, 0, 0, 0.08);
    color: var(--c-fig-secondary-50);
    display: flex;
    flex-flow: column;
    justify-content: center;
    padding: 0;

    height: 12rem;
    width: 12rem;

    &.selected {
      border: 2px solid var(--c-fig-secondary-50);
    }

    &-output {
      color: var(--c-fig-accent);

      &.selected {
        border: 2px solid var(--c-fig-accent);
      }
    }

    &-input {
      color: var(--c-blue-50);

      &.selected {
        border: 2px solid var(--c-blue-50);
      }
    }

    &-placeholder {
      background-color: var(--c-gray-10);
      border: 6px dashed var(--c-black-10);

      &.selected {
        border: 6px dashed var(--c-black-20);
      }
    }

    &-text {
      background-color: white;
      height: 16rem;
      width: 38rem;
      padding: 0;

      textarea {
        height: 100%;
        border: none;
      }
    }

    &-ml {
      background-color: var(--c-white);
      height: 12rem;
      width: 36rem;

      &.selected {
        border: 2px solid var(--c-fig-secondary-50);
      }

      .react-flow__handle-bottom {
        bottom: -1px;
        
        &-left {
          left: 25%;
        }
        
        &-center {
          left: 50%;
        }
        
        &-right {
          left: 75%;
        }
      }
    }

    &--scheduled {
      background-color: var(--c-green-10);
      border: 2px solid var(--c-green-20);
    }

    &-default.selected:hover,
    &-output.selected:hover,
    &-input.selected:hover {
      box-shadow: 4px 10px 30px rgba(0, 0, 0, 0.08);
    }

    button {
      color: var(--c-black-50);

      &:hover {
        color: var(--c-fig-secondary);
      }
    }

    p {
      color: var(--c-black-50);
    }

    & > i.fa-fw {
      height: 100%;
      cursor: inherit;
      width: 100%;
      padding: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 5.6rem;
    }

    & > img {
      pointer-events: none;
    }

    &:hover,
    &.selected {
      .react-flow__buttons {
        display: flex;
      }
    }
  }

  .react-flow__handle {
    align-items: center;
    background-color: var(--c-transparent);
    border-radius: 0;
    border: none;
    display: flex;
    height: 1px;
    justify-content: center;
    right: 0;
    width: 1px;
    z-index: var(--z-ui);

    &::after {
      background-color: var(--c-black-20);
      border-radius: 100%;
      border: none;
      content: '';
      display: block;
      height: 1.6rem;
      position: absolute;
      transition: all 0.125s ease;
      width: 1.6rem;
    }

    // Input
    &-left {
      left: -1px;
    }

    // Output
    &-right {
      right: -1px;
    }

    &-connecting::after {
      background-color: var(--c-fig-secondary);
      transform: scale(1.4);
    }
  }

  .react-flow__arrowhead polyline {
    stroke: var(--c-transparent);
    fill: var(--c-transparent);
  }

  .react-flow__edges {
    .react-flow__connection-path,
    .react-flow__edge-path {
      stroke: var(--c-black-20);
      stroke-width: 3;
    }

    .react-flow__edge.selected .react-flow__edge-path {
      stroke: var(--c-black);
    }
  }

  .react-flow__buttons {
    display: none;
    justify-content: center;
    padding: 0.8rem;
    position: absolute;
    top: 100%;
    width: 100%;

    button {
      padding: 0.8rem;
    }
  }

  .react-flow__controls {
    display: flex;
    flex-direction: row;
    bottom: 1.6rem;
    font-size: 1.6rem;
    left: 1.6rem;

    &-button {
      width: 24px;
      height: 24px;

      svg {
        max-height: 16px;
        max-width: 16px;
      }
    }
  }
}

.dndflow {
  display: flex;
  height: 100%;
}

.reactflow-wrapper {
  height: calc(100vh - 6.8rem); // 6.8rem is the height of the header
}
