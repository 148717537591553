.gf-icon {
    &--small {
        width: 2rem;
        height: 2rem;
    }

    &--medium {
        width: 2.4rem;
        height: 2.4rem;
    }

    // User avatar in event logs table
    &--large {
        width: 3.2rem;
        height: 3.2rem;
    }

    // User avatar in team sidebar
    &--xlarge {
        width: 4rem;
        height: 4rem;
    }
}