.gf-notifications {
    display: flex;
    flex-direction: column;
    gap: 4rem;

    &--list {
        max-height: 48rem;
        display: flex;
        flex-direction: column;
        gap: .4rem;
        overflow-y: auto;

        >li {
            padding: 1.2rem 1.6rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-radius: .4rem;

            i {
                margin-right: .8rem;
            }
        }

        &--item--new {
            background-color: var(--gf-primary-50);
        }
    }

    &--footer {
        display: flex;
        justify-content: center;
    }
}