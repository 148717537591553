@use "../layout/gf-breakpoints" as *;

/*=============================================
=          Go Fig Typography System          =
=============================================*/

// Core typography variables

$gf-typography: (
  "font-weights": (
    "regular": 400,
    "medium": 500,
    "semibold": 600,
    "bold": 700,
    "extrabold": 800
  ),
  "letter-spacing": (
    "tight": -0.01em,
    "normal": 0,
    "wide": 0.01em
  )
);

// Helper functions

@function get-font-weight($weight) {
  @return map-get(map-get($gf-typography, "font-weights"), $weight);
}

@function get-letter-spacing($spacing) {
  @return map-get(map-get($gf-typography, "letter-spacing"), $spacing);
}

// Mixins

@mixin gf-font-weight($weight) {
  font-weight: get-font-weight($weight);
}

// Classes

.gf-regular {
  @include gf-font-weight("regular");
}

.gf-medium {
  @include gf-font-weight("medium");
}

.gf-semibold {
  @include gf-font-weight("semibold");
}

.gf-bold {
  @include gf-font-weight("bold");
}

.gf-extrabold {
  @include gf-font-weight("extrabold");
}

// Original:

// // Font weight variables
// $gf-font-weight-regular: 400;
// $gf-font-weight-medium: 500;
// $gf-font-weight-semibold: 600;
// $gf-font-weight-bold: 700;
// $gf-font-weight-extrabold: 800;

// .gf-regular { font-weight: $gf-font-weight-regular; }
// .gf-medium { font-weight: $gf-font-weight-medium; }
// .gf-semibold { font-weight: $gf-font-weight-semibold; }
// .gf-bold { font-weight: $gf-font-weight-bold; }
// .gf-extrabold { font-weight: $gf-font-weight-extrabold; }

// // Letter spacing variables
// $gf-letter-spacing-tight: -0.02em;
// $gf-letter-spacing-wide: 0.01em;

/*===========  Display Typography  ============

Used for hero sections, large headlines, etc.

Syntax:
.gf-display--{size}

Usage:
<h1 class="gf-display--large">Welcome to Go Fig</h1>

*/

// Mixins

@mixin gf-display {
  letter-spacing: get-letter-spacing("tight");
}

@mixin gf-display--large {
  @include gf-display;
  font-size: 5.2rem;
  line-height: 5.6rem;
}

@mixin gf-display--small {
  @include gf-display;
  font-size: 4.4rem;
  line-height: 4.8rem;
}

// Class

.gf-display {
  @include gf-display;

  &--large {
      @include gf-display--large;
  }
  &--small {
      @include gf-display--small;
  }
}

// Original:

// .gf-display {
//   letter-spacing: $gf-letter-spacing-tight;

//   &--large {
//     font-size: 5.2rem;
//     line-height: 5.6rem;
//   }
//   &--small {
//     font-size: 4.4rem;
//     line-height: 4.8rem;
//   }
// }

/*===========  Heading Typography  ============

Responsive heading styles applied to header elements <h1> - <h6>
Can also be used as a class

Syntax:
.gf-heading--{size}

Examples:
<h1>Page Title</h1>
<span class="gf-heading--h2">Section Header</span>

*/

$gf-headings: (
    "h1": (
      desktop: (font-size: 4rem, line-height: 4.8rem),
      mobile: (font-size: 3.6rem, line-height: 4.4rem)
    ),
    "h2": (
      desktop: (font-size: 3.6rem, line-height: 4.4rem),
      mobile: (font-size: 3.2rem, line-height: 4rem)
    ),
    "h3": (
      desktop: (font-size: 3.2rem, line-height: 4rem),
      mobile: (font-size: 2.8rem, line-height: 3.6rem)
    ),
    "h4": (
      desktop: (font-size: 2.8rem, line-height: 3.6rem),
      mobile: (font-size: 2.4rem, line-height: 3.2rem)
    ),
    "h5": (
      desktop: (font-size: 2.4rem, line-height: 3.2rem),
      mobile: (font-size: 2rem, line-height: 2.8rem)
    ),
    "h6": (
      desktop: (font-size: 2rem, line-height: 2.8rem),
      mobile: (font-size: 1.8rem, line-height: 2.4rem)
    )
);

// Helper functions

@function get-heading-value($header-level, $screen-size, $property) {
  @return map-get(map-get(map-get($gf-headings, $header-level), $screen-size), $property);
}

@function get-heading-desktop($header-level, $property) {
  @return get-heading-value($header-level, "desktop", $property);
}

@function get-heading-mobile($header-level, $property) {
  @return get-heading-value($header-level, "mobile", $property);
}

// Mixins

@mixin _gf-heading($header-level) {
  font-size: get-heading-mobile($header-level, "font-size");
  line-height: get-heading-mobile($header-level, "line-height");
  letter-spacing: get-letter-spacing("tight");

  @include gf-breakpoint-up("medium") {
      font-size: get-heading-desktop($header-level, "font-size");
      line-height: get-heading-desktop($header-level, "line-height");
  }
}

@mixin gf-heading--h1 {
  @include _gf-heading("h1");
}

@mixin gf-heading--h2 {
  @include _gf-heading("h2");
}

@mixin gf-heading--h3 {
  @include _gf-heading("h3");
}

@mixin gf-heading--h4 {
  @include _gf-heading("h4");
}

@mixin gf-heading--h5 {
  @include _gf-heading("h5");
}

@mixin gf-heading--h6 {
  @include _gf-heading("h6");
}

// Header elements and classes

h1, .h1, .gf-heading--h1 { @include gf-heading--h1; }
h2, .h2, .gf-heading--h2 { @include gf-heading--h2; }
h3, .h3, .gf-heading--h3 { @include gf-heading--h3; }
h4, .h4, .gf-heading--h4 { @include gf-heading--h4; }
h5, .h5, .gf-heading--h5 { @include gf-heading--h5; }
h6, .h6, .gf-heading--h6 { @include gf-heading--h6; }

// Original:

// @mixin heading-desktop-mobile($desktop-size, $desktop-line-height, $mobile-size, $mobile-line-height) {
//   font-size: $mobile-size;
//   line-height: $mobile-line-height;
//   letter-spacing: $gf-letter-spacing-tight;

//   @media (min-width: 768px) {
//     font-size: $desktop-size;
//     line-height: $desktop-line-height;
//   }
// }

// .gf-heading {
//     &--h1 {
//       @include heading-desktop-mobile(4rem, 4.8rem, 3.6rem, 4.4rem);
//     }
//     &--h2 {
//       @include heading-desktop-mobile(3.6rem, 4.4rem, 3.2rem, 4rem);
//     }
//     &--h3 {
//       @include heading-desktop-mobile(3.2rem, 4rem, 2.8rem, 3.6rem);
//     }
//     &--h4 {
//       @include heading-desktop-mobile(2.8rem, 3.6rem, 2.4rem, 3.2rem);
//     }
//     &--h5 {
//       @include heading-desktop-mobile(2.4rem, 3.2rem, 2rem, 2.8rem);
//     }
//     &--h6 {
//       @include heading-desktop-mobile(2rem, 2.8rem, 1.8rem, 2.4rem);
//     }
//   }

//   // Legacy support for header elements and classes
//   h1, .h1 { @extend .gf-heading--h1; }
//   h2, .h2 { @extend .gf-heading--h2; }
//   h3, .h3 { @extend .gf-heading--h3; }
//   h4, .h4 { @extend .gf-heading--h4; }
//   h5, .h5 { @extend .gf-heading--h5; }
//   h6, .h6 { @extend .gf-heading--h6; }

/*==========  Paragraph Typography  ===========

Used for body text, article content, etc.

Syntax:
.gf-paragraph--{size}--{weight}
.gf-paragraph--{stye}

Usage:
<p class="gf-paragraph--small--medium gf-paragraph--italic">
    Small italic text with medium weight
</p>

*/

// Mixins

@mixin gf-paragraph--large {
  font-size: 1.8rem;
  line-height: 2.8rem;
}

@mixin gf-paragraph--medium {
  font-size: 1.6rem;
  line-height: 2.4rem;
}

@mixin gf-paragraph--small {
  font-size: 1.4rem;
  line-height: 2rem;
}

@mixin gf-paragraph--xsmall {
  font-size: 1.2rem;
  line-height: 2rem;
}

// Class

.gf-paragraph {
  &--large {
    @include gf-paragraph--large;
  }
  &--medium {
    @include gf-paragraph--medium;
  }
  &--small {
    @include gf-paragraph--small;
  }
  &--xsmall {
    @include gf-paragraph--xsmall;
  }
}

// Original:

// .gf-paragraph {
//   &--large {
//     font-size: 1.8rem;
//     line-height: 2.8rem;
//   }
//   &--medium {
//     font-size: 1.6rem;
//     line-height: 2.4rem;
//   }
//   &--small {
//     font-size: 1.4rem;
//     line-height: 2rem;
//   }
//   &--xsmall {
//     font-size: 1.2rem;
//     line-height: 2rem;
//   }
// }

/*==========  Label Typography  ===========

Compact text styles for UI elements, form labels, badges, etc.

Syntax:
.gf-label--{size}

Usage:
<label class="gf-label--medium">
    Email Address
</label>

*/

// Mixins

@mixin gf-label {
  // display: inline-block;
  // vertical-align: baseline;
  // position: relative;
  // top: .2rem;
}

@mixin gf-label--large {
  @include gf-label;
  font-size: 1.6rem;
  line-height: 1.8rem;
}

@mixin gf-label--medium {
  @include gf-label;
  font-size: 1.4rem;
  line-height: 1.6rem;
}

@mixin gf-label--small {
  @include gf-label;
  font-size: 1.2rem;
  line-height: 1.4rem;
}

@mixin gf-label--xsmall {
  @include gf-label;
  font-size: 1rem;
  line-height: 1.4rem;
}

// Class

.gf-label {
  &--large {
    @include gf-label--large;
  }
  &--medium {
    @include gf-label--medium;
  }
  &--small {
    @include gf-label--small;
  }
  &--xsmall {
    @include gf-label--xsmall;
  }
}

// Original:

// .gf-label {
//   &--large {
//     font-size: 1.6rem;
//     line-height: 1.8rem;
//   }
//   &--medium {
//     font-size: 1.4rem;
//     line-height: 1.6rem;
//   }
//   &--small {
//     font-size: 1.2rem;
//     line-height: 1.4rem;
//   }
//   &--xsmall {
//     font-size: 1rem;
//     line-height: 1.4rem;
//   }
// }

/*============ Overline Typography ============

Small, uppercase text placed above headings or content blocks

Syntax:
.gf-overline--{size}

Usage:
<span class="gf-overline--large">
    New Feature
</span>

*/

// Mixins

@mixin gf-overline {
  font-weight: get-font-weight("semibold");
  letter-spacing: get-letter-spacing("wide");
  text-transform: uppercase;
}

@mixin gf-overline--large {
  @include gf-overline;
  font-size: 1.4rem;
  line-height: 2rem;
}

@mixin gf-overline--small {
  @include gf-overline;
  font-size: 1.2rem;
  line-height: 2rem;
}

// Class

.gf-overline {
  @include gf-overline;

  &--large {
    @include gf-overline--large;
  }
  &--small {
    @include gf-overline--small;
  }
}

// Original:

// .gf-overline {
//   font-weight: $gf-font-weight-semibold;
//   letter-spacing: $gf-letter-spacing-wide;
//   text-transform: uppercase;

//   &--large {
//     font-size: 1.4rem;
//     line-height: 2rem;
//   }
//   &--small {
//     font-size: 1.2rem;
//     line-height: 2rem;
//   }
// }

/*=============== Inline styles ===============

Usage:
<span class="gf-center gf-underline">
    Underlined centered text
</span>

*/

.gf-center {
  text-align: center;
}

.gf-underline {
  text-decoration: underline;
}

.gf-strikethrough {
  text-decoration: line-through;
}

.gf-italic {
  font-style: italic;
}

.helptext {
  font-size: 1.2rem;
  // line-height: 1.2rem;
  color: var(--gf-neutral-400);
}

/*================== Other ==================*/

.gf-label--small--muted {
  @extend .gf-label--small;
  color: var(--gf-neutral-400);
}

.gf-label--xsmall--muted {
  @extend .gf-label--xsmall;
  color: var(--gf-neutral-400);
}

.gf-muted {
  color: var(--gf-neutral-400);
}

.gf-underline {
  text-decoration: underline;
}