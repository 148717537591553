@use '../../base/styles/utilities/mixins';

// Navigational sidebar present throughout the site.
//
// Use is slightly complicated, see
// `web/templates/web/base.html` for detailed implementation.
.sidebar {
  background-color: var(--c-white);
  border-right: 1px solid var(--c-gray);
  display: flex;
  list-style-type: none;

  &__body {
    display: flex;
    flex-flow: column;
    flex: 1 1 0%;
    overflow: hidden;
    transition: width 0.25s ease;
    white-space: nowrap;
    width: 4.8rem;
  }

  &__link,
  &__item {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    font-size: 1.4rem;
    height: 4.2rem;
    line-height: 2rem;
    padding: 0 0.6rem 0 1.2rem;
    position: relative;
    text-align: left;

    @include mixins.fa-icon {
      margin: {
        left: 0.325rem;
        right: 1.5rem;
      }
      min-width: 1.25em;
    }

    &--border {
      border-bottom: 1px solid var(--c-gray);
    }

    .h3 {
      line-height: 2rem;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &::before {
      background-color: var(--c-transparent);
      content: '';
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 0.6rem;
    }
  }

  &__link {
    color: var(--c-black);

    &--active {
      background-color: var(--c-gray-10);
      color: var(--c-fig-secondary);
      font-weight: 500;

      @include mixins.fa-icon {
        font-weight: 900;
      }

      &::before {
        background-color: var(--c-fig-secondary);
      }
    }

    &:hover {
      background-color: var(--c-gray-10);
    }

    img {
      border-radius: 5px;
      display: inline-flex;
      line-height: 2.4rem;
      margin-right: 1.2rem;
      min-height: 2.4rem;
      min-width: 2.4rem;
      width: 2.4rem;
    }
  }

  &__bar {
    height: 0;
    position: relative;
    z-index: var(--z-ui);

    &::after {
      background-color: var(--c-gray);
      content: '';
      height: 1.6rem;
      left: 2.2rem;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      width: 0.3rem;
    }
  }

  &__title {
    align-items: center;
    border-bottom: 1px solid var(--c-gray);
    display: flex;
    flex-shrink: 0;
    font-size: 1.4rem;
    font-weight: 500;
    height: 4.2rem;

    div {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    i {
      color: var(--c-fig-secondary);
    }
  }

  #sidebar-toggle {
    visibility: hidden;

    &:checked ~ .sidebar__body {
      width: 24rem;

      // Swaps the icon of the sidebar toggle to the opposite chevron.
      label[for='sidebar-toggle'] i::before {
        content: '\f323';
      }
    }

    &:not(:checked) ~ .sidebar__body {
      .sidebar__title {
        padding: 0;

        div {
          font-size: 0;
          flex: 1 1 0%;
          text-align: center;
          line-height: normal;
        }

        div:first-letter {
          font-size: 1.8rem;
        }

        div::after {
          content: '.';
          font-size: 1.8rem;
          display: inline-block;
        }

        i {
          display: none;
        }
      }
    }
  }

  label[for='sidebar-toggle'] {
    cursor: pointer;
    gap: 0;

    i::before {
      content: '\f324';
    }
  }
}
