@use '../../base/styles/utilities/mixins';
@use '../../base/styles/utilities/_gf-utilities' as *;
@use '../../base/styles/base/gf-typography' as *;

.workflow {
  &-detail {
    padding: 0;

    &__header {
      padding: .8rem;
    }

    // &__footer,
    // &__header {
    //   width: 100%;
    //   padding: 1.2rem 2.4rem;
    //   align-items: center;
    //   background-color: var(--c-white);
    //   border-bottom: 1px solid var(--gf-neutral-200);
    //   border-radius: 0 0 .8rem .8rem;
    //   display: flex;
    //   gap: 1.6rem;
    //   flex-shrink: 0;
    // }

    &__footer {
      border-bottom: 0;
      border-top: 1px solid var(--gf-neutral-200);
      box-sizing: content-box;
    }

    &__sidebar {
      background-color: var(--c-white);
      flex: 1 1 0%;
      overflow: auto;
      padding: 1.6rem;

      &__loading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &__warning {
      align-items: center;
      display: flex;
      flex-direction: column;
      flex: 1 1 0%;
      justify-content: center;

      @include mixins.fa-icon {
        color: var(--c-orange);
      }
    }

    #nodes:grid {
      overflow: auto;
      height: 100%;
    }
  }
}

#workflow-modal,
.workflow-modal {
  display: flex;
  flex: 1 1 0%;
  height: 100%;

  &__section {
    position: relative;
    display: flex;
    flex-flow: column;
    width: 40%;

    & + & {
      overflow: hidden;
      width: 60%;
    }
  }

  &__header {
    align-items: center;
    border-bottom: 1px solid var(--gf-neutral-200);
    display: flex;
    height: 4.2rem;
    max-height: 4.2rem;
    padding: 1.6rem;
  }

  &__footer {
    border-top: 1px solid var(--gf-neutral-200);
    display: flex;
    margin-top: auto;

    input.button {
      flex: 1 1 0%;
    }
  }

  &__table {
    border-left: 1px solid var(--gf-neutral-200);
    display: flex;
    flex-flow: column;
    flex: 1 1 0%;
    height: 100%;
    overflow: hidden;
    width: 100%;

    #nodes-grid {
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow: hidden;
    }

    .table-container {
      overflow: auto;
    }
  }
}

#run-button-portal {
  display: flex;
  gap: 1.6rem;
}