.node {
  &__header {
    display: flex;
    align-items: center;
    text-transform: capitalize;
    margin-bottom: 1.6rem;

    h3 {
      margin-right: auto;
    }
  }

  &__visual {
    align-items: center;
    display: flex;
    flex-flow: column;
    height: 100%;
    justify-content: center;
    padding: 1.6rem;
    width: 100%;
  }
}
