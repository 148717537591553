.gf-dnd--settings {
    min-height: 600px;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    // Optional container for including sidebar
    &--container {
        display: flex;
        flex-direction: row;
        gap: 2rem;
    }

    &--content {
        display: flex;
        flex-direction: column;
        gap: 1.6rem;
        flex: 1;

        &--container {
            overflow-y: auto;
            display: flex;
            flex-direction: column;
            gap: 1.6rem;
            flex: 1;

            >form {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                gap: 2rem;
            }
        }
    }
}