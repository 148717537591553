@use "sass:map";
@use "../base/_gf-typography.scss";

/*=============================================
=            Go Fig Utility Styles            =
=============================================*/

/*=============== Drop Shadow ===============*/

$shadow-sizes: (
    "none": (0 0 0 rgba(16, 24, 40, 0)),
    "xsmall": (0 1px 2px rgba(16, 24, 40, 0.04)),
    "small": (0 2px 6px rgba(16, 24, 40, 0.06)),
    "medium": (0 6px 15px -2px rgba(16, 24, 40, 0.08)),
    "large": (0 8px 24px -3px rgba(16, 24, 40, 0.05)),
    "xlarge": (0 20px 40px -8px rgba(16, 24, 40, 0.05)),
    "xxlarge": (0 25px 60px -15px rgba(16, 24, 40, 0.12))
);

@mixin gf-drop-shadow($size) {
    $shadow: map-get($shadow-sizes, $size);
    box-shadow: $shadow;
}

@each $size, $shadow in $shadow-sizes {
    .gf-drop-shadow--#{$size} {
        @include gf-drop-shadow($shadow);
    }
}

/*============== Border Radius ==============*/

$radius-sizes: (
    "small": 0.4rem,
    "medium": 0.8rem,
    "large": 1.2rem,
    "xlarge": 1.6rem,
    "xxlarge": 2rem
);

@mixin gf-border-radius($size) {
    $radius: map-get($radius-sizes, $size);
    border-radius: $radius;
}

@each $size, $radius in $radius-sizes {
    .gf-border-radius--#{$size} {
        @include gf-border-radius($radius);
    }
}

/*================= Padding =================*/

$padding-sizes: (
    "xsmall": 0.4rem,
    "small": 0.8rem,
    "medium": 1.2rem,
    "large": 1.6rem,
    "xlarge": 2rem,
    "xxlarge": 2.4rem
);

@mixin gf-padding($size) {
    $padding: map-get($padding-sizes, $size);
    padding: $padding;
}

@each $size, $padding in $padding-sizes {
    .gf-padding--#{$size} {
        @include gf-padding($padding);
    }
}

/*================== Layout =================*/

.gf-full-height {
    height: 100%;
}

.gf-full-width {
    width: 100%;
}

.gf-round {
    border-radius: 50%;
}

/*=================== Flex ==================*/

.gf-flex--center {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

$gap-sizes: (
  "0-4": 0.4rem,
  "0-8": 0.8rem,
  "1-2": 1.2rem,
  "1-6": 1.6rem,
  "2": 2rem,
  "2-4": 2.4rem,
  "2-8": 2.8rem,
  "3-2": 3.2rem,
  "3-6": 3.6rem,
  "4": 4rem
);

.gf-flex--gap {
  @each $name, $size in $gap-sizes {
    &-#{$name} {
      gap: $size;
    }
  }
}

/*=============================================
=              Go Fig Animations              =
=============================================*/

$animation-timings: (
    "linear": linear,
    "ease": ease,
    "ease-in": ease-in,
    "ease-out": ease-out,
    "ease-in-out": ease-in-out
);

$animation-durations: (
    "xfast": .5s,
    "fast": 1s,
    "normal": 2s,
    "slow": 7s,
);

/*================ Slide In =================*/

$animation-placements: (
    "left": "left",
    "right": "right"
);

// Mixins
@mixin gf-slide-in($placement: "right", $duration: "normal", $timing: "ease-out") {
    $duration-value: map.get($animation-durations, $duration);
    $timing-value: map.get($animation-timings, $timing);
    $direction: map.get($animation-placements, $placement);

    animation: slide-#{$direction} $duration-value $timing-value forwards;

    @keyframes slide-#{$direction} {
        @if $direction == "left" {
            0% {
                transform: translateX(-100%);
            }
            100% {
                transform: translateX(0);
            }
        } @else {
            0% {
                transform: translateX(100%);
            }
            100% {
                transform: translateX(0);
            }
        }
    }
}

// Classes:
// <div class="gf-slide-in">Slide in from right at normal speed</div>
// <div class="gf-slide-in--left">Slide in from left at normal speed</div>
// <div class="gf-slide-in--left--slow">Slide in from left at slow speed</div>
// <div class="gf-slide-in--right--fast">Slide in from right at fast speed</div>

// Base class (defaults to right)
.gf-slide-in {
    @include gf-slide-in();
}

// Direction variants
@each $placement in map.keys($animation-placements) {
    .gf-slide-in--#{$placement} {
        @include gf-slide-in($placement);
    }

    // Direction + duration variants
    @each $duration, $duration-value in $animation-durations {
        .gf-slide-in--#{$placement}--#{$duration} {
            @include gf-slide-in($placement, $duration);
        }
    }
}

/*================== Fade ===================*/

$animation-types: (
    "in": "in",
    "out": "out"
);

@mixin gf-fade($type: "in", $duration: "slow", $timing: "ease-out") {
    $duration-value: map.get($animation-durations, $duration);
    $timing-value: map.get($animation-timings, $timing);
    $direction: map.get($animation-types, $type);

    animation: fade-#{$direction} $duration-value $timing-value forwards;

    @keyframes fade-#{$direction} {
        @if $direction == "in" {
            0% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
        } @else {
            0% {
                opacity: 1;
            }
            100% {
                opacity: 0;
            }
        }
    }
}

.gf-fade-in {
    @include gf-fade("in");
}

.gf-fade-out {
    @include gf-fade("out");
}

@each $type in map.keys($animation-types) {
    @each $duration, $duration-value in $animation-durations {
        .gf-fade-#{$type}--#{$duration} {
            @include gf-fade($type, $duration);
        }
    }
}

/*================== Hover ==================*/

// Base

%gf-hover-base {
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    >a {
        text-decoration: none;
    }
}

// Variables

$hover-effects: (
    "fade": (opacity: 0.8,
        shadow: none,
        color: none,
        background: none),
    "shadow": (opacity: 1,
        shadow: "small",
        color: none,
        background: none),
    "lighten": (opacity: none,
        shadow: none,
        color: var(--gf-primary-500),
        background: var(--gf-generic-white)),
    "darken": (opacity: 0.8,
        shadow: none,
        color: none,
        background: var(--gf-neutral-300)),
    "primary": (opacity: none,
        shadow: none,
        color: var(--gf-generic-white),
        background: var(--gf-primary-500))
);

// Mixin

@mixin gf-hover($effect: "fade") {
    @extend %gf-hover-base;

    &:hover {
        $config: map-get($hover-effects, $effect);
        text-decoration: none;

        @if map-get($config, "opacity") !="none" {
            opacity: map-get($config, "opacity");
        }

        @if map-get($config, "shadow") !="none" {
            @include gf-drop-shadow(map-get($config, "shadow"));
        }

        @if map-get($config, "background") !="none" {
            background-color: map-get($config, "background");
        }

        @if map-get($config, "color") !="none" {
            color: map-get($config, "color");
        }
    }
}

// Classes
// <button class="gf-hover--fade">Fade</button>
// <button class="gf-hover--shadow">Shadow</button>
// <button class="gf-hover--lighten gf-hover--shadow">Lighten and shadow</button>

@each $effect, $config in $hover-effects {
    .gf-hover--#{$effect} {
        @include gf-hover($effect);
    }
}

/*================== Spin ===================*/

@mixin gf-spin($duration: "normal", $timing: "linear") {
    $duration-value: map.get($animation-durations, $duration);
    $timing-value: map.get($animation-timings, $timing);

    animation: spin $duration-value $timing-value infinite;

    @keyframes spin {
        from { transform: rotate(0deg); }
        to { transform: rotate(360deg); }
    }
}

// Base class with default duration
.gf-spin {
    @include gf-spin();
}

// Duration variants
@each $duration, $duration-value in $animation-durations {
    .gf-spin--#{$duration} {
        @include gf-spin($duration);
    }
}

/*================= Loading =================*/

@mixin gf-loading--ellipsis {
    display: flex;
    gap: 4px;

    span {
        @extend .gf-muted;
        @extend .gf-display--small;
        opacity: 0;
        animation: loadingEllipsis 1.5s ease-in-out infinite;

        &:nth-child(2) {
            animation-delay: 0.2s;
        }
        &:nth-child(3) {
            animation-delay: 0.4s;
        }
    }

    @keyframes loadingEllipsis {
        0% { opacity: 0; }
        50% { opacity: 1; }
        100% { opacity: 0; }
    }
}

.gf-loading--ellipsis {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: none;
    justify-content: center;
    align-items: center;

    &.htmx-request {
        display: flex;
    }

    @include gf-loading--ellipsis();
}
