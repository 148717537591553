.project-card {
  display: flex;
  flex-flow: column;
  padding: 1.6rem;
  position: relative;

  & + & {
    border-top: 1px solid var(--c-gray);
  }

  a {
    color: var(--c-fig-secondary);
    font-weight: 500;
    margin-bottom: 0.3rem;
  }

  header {
    display: flex;
    align-items: center;

    i {
      color: var(--c-black-50);
    }
  }

  footer {
    align-items: center;
    display: flex;
    font-size: 1.2rem;
    gap: 1.6rem;
    margin-top: 1.6rem;

    a,
    p {
      color: var(--c-black-50);
      display: flex;
      font-size: inherit;
      font-weight: normal;
      gap: 0.3rem;
      margin-bottom: 0;
    }

    a:hover {
      color: var(--c-fig-secondary-50);
      transition: color 0.1s ease;
    }
  }

  &__quota {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: var(--c-fig-secondary-20);
    width: attr(data-percentage, "%");
    height: 3px;
  }
}
