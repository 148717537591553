@use "../base/_gf-typography.scss" as *;
@use "../utilities/_gf-utilities.scss" as *;
@use "./_gf-button.scss" as *;

.gf-figbot {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.gf-chat--messages {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    justify-content: flex-end;

    .gf-chat--message {
        padding: .8rem 2rem;
        display: flex;
        gap: 1.2rem;

        &--user {
            @extend .gf-chat--message;
            flex-direction: row-reverse;
            justify-content: flex-start;
            text-align: right;
        }

        &--system {
            @extend .gf-chat--message;
        }

        &--icon {
            width: 4rem;
            min-width: 4rem;
            height: 4rem;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &--contents {
            display: flex;
            flex-direction: column;

            &--header {
                @include gf-paragraph--xsmall;
                color: var(--gf-neutral-500);
            }
        }
    }


}

.gf-chat--input-bar {
    width: 100%;
    padding: 2rem;
    display: flex;
    gap: .8rem;

    // .gf-input--chat {

    // }
}