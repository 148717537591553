@import "../../base/styles/base/_gf-base.scss";
@import "../../base/styles/components/_gf-button.scss";
@import "../../base/styles/components/_gf-sidebar.scss";
@import "../../base/styles/utilities/_gf-utilities.scss";

/*=============================================
=         Go Fig Chat Sidebar Styles         =
=============================================*/

/*========= Base styles (collapsed) =========*/

.gf-sidebar--chat {
    @extend .gf-sidebar;
    padding: 0;
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    background-color: var(--gf-primary-100);
    border-radius: .8rem 0 0 .8rem;
    transition: 400ms ease;
    z-index: 1000;

    &--left-panel {
        width: 0;
        min-width: 0;
        height: 100%;
        margin-left: 0;
        opacity: 0;
        visibility: hidden;

        .gf-sidebar--chat--window {
            min-width: 0;
            height: 100%;
            margin-left: 0;
            opacity: 0;
            visibility: hidden;
            background-color: var(--gf-primary-50);
        }
    }

    .gf-sidebar--menu {
        width: 8rem;
        max-width: 8rem;
        padding: 2rem;
        justify-content: end;

        .gf-sidebar--menu-item {
            justify-content: end;

            .gf-icon--chat {
                max-width: 2.4rem;
                max-height: 2.4rem;
            }
        }
    }

    .no-transition {
        transition: none;
    }
}

/*============ Full width styles ============*/

.gf-sidebar--chat.full-width {
    width: 100%;

    .gf-sidebar--chat--left-panel {
        @include gf-hidden;
    }

    .gf-sidebar--chat--right-panel {
        @include gf-visible;
    }
}

/*============ Collapsed styles =============*/

.gf-sidebar--chat.collapsed {
    width: 8rem;

    .gf-sidebar--chat--left-panel {
        @include gf-hidden;
    }

    .gf-sidebar--chat--right-panel {
        @include gf-hidden;
    }
}

/*============ Half expanded styles =============*/

.gf-sidebar--chat.half-expanded {
    width: 56rem;

    .gf-sidebar--chat--left-panel {
        @include gf-visible;
    }

    .gf-sidebar--chat--right-panel {
        @include gf-hidden;
    }
}

/*============= Expanded styles =============*/

.gf-sidebar--chat.expanded {
    width: 112rem;

    .gf-sidebar--chat--right-panel {
        @include gf-hidden;
    }

    .gf-sidebar--chat--left-panel {
        @include gf-visible;
        flex: 1;
        display: flex;
        flex-direction: row;

        .gf-sidebar--chat--features {
            min-width: 52rem;
            height: 100%;
            width: 100%;
            padding: 8rem;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 2.8rem;

            &--header {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 1.6rem;

                &--logo {
                    width: 24rem;
                    height: 24rem;
                    margin: 1.2rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        height: 24rem;
                        width: auto;
                    }
                }
            }

            ul {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 1.6rem;

                .gf-sidebar--chat--feature {
                    @include gf-border-radius("large");
                    @include gf-padding("xlarge");
                    @include gf-hover("lighten");
                    @include gf-hover("shadow");
                    width: 100%;
                    color: var(--gf-primary-500);
                    background-color: var(--gf-primary-50);
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: .4rem;

                    >span {
                        @include gf-font-weight("bold");
                    }

                    >p {
                        @include gf-paragraph--xsmall;
                        color: var(--gf-primary-400);
                        text-align: center;
                    }
                }
            }
        }

        .gf-sidebar--chat--window {
            min-width: 52rem;
            opacity: 1;
            visibility: visible;
            flex: 1;
        }
    }
}