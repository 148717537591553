@use "../../base/styles/components/gf-scrollbar";
@use "../../base/styles/layout/gf-layout";

.gf-project {
  width: 100%;
  display: flex;

  &--content {
    position: relative;
    width: 100%;
    margin-right: 8rem;
    display: flex;
    flex-direction: column;

    &--container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .gf-project--page {
        @extend .gf-scrollbar-hidden;
        width: 100%;
        height: 100vh;
        padding: 4rem;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        gap: 2rem;

        &--header {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          &--text {
            display: flex;
            flex-direction: column;
            gap: .4rem;
          }

          &--actions {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: .8rem;
          }
        }

        &--content {
          display: flex;
          flex-direction: column;
          gap: 2rem;
        }

        &--table {
          @extend .gf-project--page;
          padding: 4rem 0;
          gap: 3.2rem;

          .gf-project--page--header {
            padding: 0 2.4rem;
          }
        }
      }
    }
  }
}

#pinned-dashboard-container {
  width: 100%;
  height: 100%;

  header {
    margin: 0 0 1.6rem 0;
    height: auto;
  }

  footer {
    padding: 0;
  }
}