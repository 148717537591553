form,
.form {
  align-items: flex-start;
  display: flex;
  flex-flow: column;
  gap: 1.2rem;

  &.form--no-layout {
    align-items: inherit;
    display: inherit;
    flex-flow: inherit;
    gap: inherit;
  }

  .form__extra {
    width: 100%;

    summary {
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }

    input {
      box-sizing: border-box;
    }
  }
}
