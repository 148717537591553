@use '../utilities' as u;

.list {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  padding: 0.4rem;
  width: max-content;

  &--bordered {
    border-radius: 10px;
    border: 1px solid var(--c-gray);
    background-color: var(--c-white);
  }

  &__item {
    align-items: center;
    border-radius: 5px;
    display: flex;
    min-height: 32px;
    padding: 0 0.8rem;

    &--interactive {
      cursor: pointer;

      &:hover {
        background-color: var(--c-gray-20);
      }
    }

    &--br {
      font-weight: bold;
      text-align: right;
      border-bottom: 1px solid var(--c-gray);
    }

    &[disabled],
    &.disabled {
      color: var(--c-black-20);
      cursor: default;
    }

    &[x-data*='popover']::after,
    [x-data*='popover'] > &::after {
      color: var(--c-black-50);
      content: '\f054';
      display: inline;
      font-family: 'Font Awesome 5 Pro';
      font-size: 1.2rem;
      font-weight: normal;
      padding: 0 0 0 u.size('sm');
      margin-left: auto;
    }
  }
}
