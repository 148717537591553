.project {
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
}

.project-detail {
  align-items: flex-start;
  display: flex;
  gap: 3.2rem;
  justify-content: center;
  padding: 6.4rem 1.6rem;

  > a {
    align-items: center;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  &__card {
    align-items: center;
    display: flex;
    margin-bottom: 1.6rem;
    position: relative;

    i:first-child {
      background: var(--c-fig-secondary);
      color: var(--c-white);
      padding: 1.6rem;
      border-radius: 10px;
      position: relative;
      width: 12rem;
      height: 12rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  h2 {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.8rem;
  }

  i {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 12rem;
  }
}

.project-settings {
  display: flex;
  flex-flow: column;
  flex: 1 1 0%;
  overflow: auto;

  &__title {
    margin-bottom: 1rem;
  }

  &__form {
    max-width: 32rem;
    flex: 1 1 0%;
  }
}

// Disable selecting minutes in time picker
// https://stackoverflow.com/a/61934417/15425660
input[type='time']::-webkit-calendar-picker-indicator {
  background: none;
}

#pinned-dashboard-container {
  header {
    margin: 0 0 1.6rem 0;
    height: auto;
  }

  footer {
    padding: 0;
  }
}
