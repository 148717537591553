@import '../../base/styles/base/_gf-typography.scss';
@import '../../base/styles/components/_gf-sidebar.scss';
@import '../../base/styles/utilities/_gf-utilities.scss';

.gf-sidebar--settings {
    @extend .gf-sidebar;
    width: 24rem;
    height: calc(100vh - 4rem - 3.2rem);
    padding: 2rem 3.6rem;
    color: var(--gf-neutral-500);
    background-color: var(--gf-neutral-100);
    border: 1px solid var(--gf-neutral-200);
    border-radius: 1.6rem;
    gap: 1.6rem;

    .gf-sidebar--menu-section-title,
    .gf-sidebar--menu-item {
        justify-content: end;
    }

    .gf-sidebar--menu-section-title {
        @include gf-font-weight("semibold");
        width: 100%;
        display: flex;
    }

    .gf-sidebar--menu-item--icon {
        flex: 1;
    }

    .gf-sidebar--menu-item--label {
        @include gf-hover("darken");
        @extend .gf-label--medium;
        text-align: right;
    }
}
