.plan {
  align-items: center;
  display: flex;
  gap: 1.6rem;
  justify-items: center;
  margin: 0 auto;

  &__card {
    display: flex;
    flex-direction: column;
    height: 48rem;
    padding: 0;
    width: 24rem;

    > div {
      display: flex;
      flex-direction: column;
      flex: 1 1 0%;
      padding: 1.6rem;
    }
  }
}
