@use "../../base/styles/utilities/_gf-utilities.scss" as *;

.gf-upload {

    &--create {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2rem;

        &--header {
            display: flex;
            align-items: center;
            gap: 1.2rem;
        }

        &--form {
            min-width: 56rem;

            >gcs-file-upload {
                width: 100%;
                @extend .gf-border-radius--small;
                padding: 8rem 12rem;
                // Generated using: https://kovart.github.io/dashed-border-generator/
                background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%23e6e6e6' stroke-width='4' stroke-dasharray='16' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
            }

            &--content {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 1.2rem;
            }
        }
    }
}