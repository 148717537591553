.modal {
  background-color: rgba(black, 0.5);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  padding: 3.2rem;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: var(--z-modal);

  &__close {
    color: var(--c-black-20);
    height: 3.2rem;
    width: 3.2rem;

    &--inline {
      height: 4.2rem;
      width: auto;
      padding: 0 1.6rem 0 0;
    }
  }

  & > :first-child {
    animation: scale-in-center 0.125s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    align-self: center;
    max-height: 100%;
    height: auto;
    max-width: 56rem;
  }

  &--small {
    & > :first-child {
      align-self: center;
      height: 75vh;
      max-height: 75vh;
      max-width: 56rem;
    }
  }

  &--wide {
    & > :first-child {
      max-width: 68rem;
    }
  }

  &--tall {
    & > :first-child {
      height: 100%;
    }
  }

  &--full {
    & > :first-child {
      align-self: unset;
      height: unset;
      max-height: 100%;
      max-width: 100%;
      margin-right: 8rem;
    }
  }
}

.modal__body {
  overflow: hidden;
  flex: 1 1 0%;

  form {
    display: flex;
    flex-direction: column;
    height: 100%;

    button[type='submit'] {
      margin-top: auto;
    }
  }
}

.modal__sidebar {
  width: 12rem;
  border-right: 1px solid var(--c-gray);
}

@keyframes scale-in-center {
  0% {
    transform: scale(0);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.gf-modal--connector {
  width: 100%;
  height: 100%;
}

#gf-modal--connector--content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.4rem;
}
