@use "./gf-icon" as *;

.gf-column {
    display: flex;
    align-items: center;
    gap: 1.2rem;

    &--text {
        display: flex;
        flex-direction: column;
    }
}