.clipboard-copy {
  padding: 0.8rem;
  color: var(--c-black-50);
  background-color: var(--c-gray-20);
  border: 1px solid var(--c-gray);
  border-radius: .4rem;
  overflow: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  button {
    margin-right: 0.8rem;

    &[data-action]:hover {
      color: var(--c-fig-secondary);
      cursor: pointer;
    }
  }

  >pre {
      white-space: nowrap;
  }
}
