.gf-sidebar {
    width: 8rem;
    min-width: 8rem;
    height: 100vh;
    // height: calc(100vh - 4rem); // Uncomment to include space for banner
    padding: 2rem;

    &--menu {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 2.4rem;

        &-section {
            display: flex;
            flex-direction: column;
            gap: 1.6rem;
            align-items: center;
            justify-content: center;
        }

        &-section--bottom {
            @extend .gf-sidebar--menu-section;
            margin-top:auto;
            // justify-content: flex-end;
        }

        &-item {
            width: 100%;
            display: flex;
            justify-content: start;
            align-items: center;
            cursor: pointer;

            &--active {
                background-color: var(--gf-neutral-200);
            }

            &--icon {
                height: 4rem;
                width: 4rem;
                min-width: 4rem;
                // background-color: var(--gf-neutral-200);
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: .8rem;

                img {
                    width: 100%;
                    height: auto;
                    max-height: 100%;
                }

                .gf-icon {
                    max-width: 2.4rem;
                    max-height: 2.4rem;
                }

                // .gf-avatar {
                //     border-radius: 50%;
                // }
            }

            &--label {
                flex: 1;

                & > * {
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }
            }
        }
    }
}