.tippy-box[data-theme~='popover'],
.tippy-box[data-theme~='popover-menu'],
.tippy-box[data-theme~='popover-add-widget'],
.tippy-box[data-theme~='popover-control'] {
  background-color: white;
  border: 1px solid var(--c-gray);
  border-radius: 5px;
  color: var(--c-black);
  width: max-content;
  max-width: 35rem;
  z-index: var(--z-modal);
}

.tippy-box[data-theme~='popover-menu'] {
  .tippy-content {
    padding: 0;

    .list__item {
      border-radius: 0;
    }

    select.list__item {
      border: 0;
      border-radius: 5px;
    }
  }
}

.tippy-box[data-theme~='popover-add-widget'] {
  max-height: 32rem;
  overflow: auto;
  white-space: nowrap;
}

.tippy-box[data-theme~='popover-control'] {
  width: max-content;
  max-width: 60rem;

  .tippy-content {
    padding: 0.8rem;
  }
}
