// Chat layout --------------------------------------------

// Chat input bar styles
.pg-chat-input-bar {
  display: flex;
  width: 100%;
  align-items: center;
  --tw-bg-opacity: 1;
  background-color: var(--gf-primary-400);
  padding: 0.5rem;
}

// Chat wrapper styles
.pg-chat-wrapper {
  display: flex;
  height: 100vh;
  width: 100%;
  flex-direction: column;
}

// Chat pane styles
.pg-chat-pane {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  margin-top: 4px;
  padding: 3px;
  overflow-y: auto;
  scrollbar-width: 2px;
  scrollbar-color: blue;
}

// Chat message styles
// (Doesn't seem to affect anything)
.pg-chat-message {
  display: flex;
  padding: 12px 16px;
  border-radius: 8px;
  flex-grow: 1;
  background-color: red; // Not being applied
  color: red; // Not being applied
}

// Chat message icon styles
// (Doesn't seem to affect anything)
.pg-chat-message svg {
  width: 8rem; // Not being applied
  height: 8rem; // Not being applied
}

// User/AI icon styles
.pg-chat-icon {
  margin-top: 0.25rem;
  min-width: 128px;
  text-align: right;
  font-weight: 700;
}

// Message sender styles
// (Doesn't seem to affect anything)
.pg-message-sender {
  color: orange;
  font-size: 3rem;
  line-height: 1rem;
}

// Message content styles
// (Doesn't seem to affect anything)
.pg-message-contents {
  color: var(--tw-prose-body);
  max-width: 65ch;
  // color: turquoise; // Not being applied
  // max-width: 40px; // Not being applied
}

// Reset paragraph margins within message contents
.pg-message-contents p {
  margin-top: 0px;
  margin-bottom: 0px;
}

// System message container - left-aligned with rounded corners
.pg-chat-message-system {
  display: flex;
  border-radius: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  align-items: center;
  justify-content: start;
}

// System message icon dimensions
.pg-chat-message-system svg {
  height: 1.5rem;
  width: 1.5rem;
}

// System message background and text colors
.pg-chat-message-system {
  --tw-bg-opacity: 1;
  background-color: var(--c-white);
  --tw-text-opacity: 1;
  color: var(--c-black);
}

// System message content text color
.pg-chat-message-system .pg-message-contents {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

// User message container - right-aligned with rounded corners
.pg-chat-message-user {
  display: flex;
  border-radius: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  align-items: center;
  justify-content: end;
}

// User message icon dimensions and alignment
.pg-chat-message-user svg {
  height: 1.5rem;
  width: 1.5rem;
  align-items: center;
  justify-content: end;
}

// User message background and text colors
.pg-chat-message-user {
  --tw-bg-opacity: 1;
  background-color: var(--c-fig-secondary-10);
  --tw-text-opacity: 1;
  color: var(--c-black);
}

// User message content text color
.pg-chat-message-user .pg-message-contents {
  --tw-text-opacity: 1;
  color: var(--c-black);
}

// Non-prose message content styles -----------------------

// Default paragraph spacing
.pg-message-contents :where(p):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}

// Lead paragraph styling (larger, prominent text)
.pg-message-contents :where([class~="lead"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-lead);
  font-size: 1.25em;
  line-height: 1.6;
  margin-top: 1.2em;
  margin-bottom: 1.2em;
}

// Link styling
.pg-message-contents :where(a):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-links);
  text-decoration: underline;
  font-weight: 500;
}

// Bold text styling
.pg-message-contents :where(strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-bold);
  font-weight: 600;
}

// Bold text within links inherits link color
.pg-message-contents :where(a strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
}

// Bold text within blockquotes inherits blockquote color
.pg-message-contents :where(blockquote strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
}

// Bold text within table headers inherits header color
.pg-message-contents :where(thead th strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
}

// Default ordered list styling with decimal numbers
.pg-message-contents :where(ol):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: decimal;
  margin-top: 1.25em;
  margin-bottom: 1.25em;
  padding-inline-start: 1.625em;
}

// Uppercase letter ordered list (A, B, C...)
.pg-message-contents :where(ol[type="A"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: upper-alpha;
}

// Lowercase letter ordered list (a, b, c...)
.pg-message-contents :where(ol[type="a"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: lower-alpha;
}

// Uppercase letter ordered list with strict type (A, B, C...)
.pg-message-contents :where(ol[type="A" s]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: upper-alpha;
}

// Lowercase letter ordered list with strict type (a, b, c...)
.pg-message-contents :where(ol[type="a" s]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: lower-alpha;
}

// Uppercase Roman numeral list (I, II, III...)
.pg-message-contents :where(ol[type="I"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: upper-roman;
}

// Lowercase Roman numeral list (i, ii, iii...)
.pg-message-contents :where(ol[type="i"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: lower-roman;
}

// Uppercase Roman numeral list with strict type (I, II, III...)
.pg-message-contents :where(ol[type="I" s]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: upper-roman;
}

// Lowercase Roman numeral list with strict type (i, ii, iii...)
.pg-message-contents :where(ol[type="i" s]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: lower-roman;
}

// Explicit decimal ordered list (1, 2, 3...)
.pg-message-contents :where(ol[type="1"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: decimal;
}

// Default unordered list styling with bullet points
.pg-message-contents :where(ul):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: disc;
  margin-top: 1.25em;
  margin-bottom: 1.25em;
  padding-inline-start: 1.625em;
}

// Ordered list marker (number/letter) styling
.pg-message-contents :where(ol > li):not(:where([class~="not-prose"], [class~="not-prose"] *))::marker {
  font-weight: 400;
  color: var(--tw-prose-counters);
}

// Unordered list bullet point styling
.pg-message-contents :where(ul > li):not(:where([class~="not-prose"], [class~="not-prose"] *))::marker {
  color: var(--tw-prose-bullets);
}

// Definition term styling in description lists
.pg-message-contents :where(dt):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  font-weight: 600;
  margin-top: 1.25em;
}

// Horizontal rule styling
.pg-message-contents :where(hr):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-color: var(--tw-prose-hr);
  border-top-width: 1px;
  margin-top: 3em;
  margin-bottom: 3em;
}

// Blockquote styling with left border and quotation marks
.pg-message-contents :where(blockquote):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  font-weight: 500;
  font-style: italic;
  color: var(--tw-prose-quotes);
  border-inline-start-width: 0.25rem;
  border-inline-start-color: var(--tw-prose-quote-borders);
  quotes: "\201C" "\201D" "\2018" "\2019";
  margin-top: 1.6em;
  margin-bottom: 1.6em;
  padding-inline-start: 1em;
}

// Blockquote opening and closing quote marks
.pg-message-contents :where(blockquote p:first-of-type):not(:where([class~="not-prose"], [class~="not-prose"] *))::before {
  content: open-quote;
}

// Blockquote opening and closing quote marks
.pg-message-contents :where(blockquote p:last-of-type):not(:where([class~="not-prose"], [class~="not-prose"] *))::after {
  content: close-quote;
}

// Heading styles (h1-h4)
.pg-message-contents :where(h1):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  font-weight: 800;
  font-size: 2.25em;
  margin-top: 0;
  margin-bottom: 0.8888889em;
  line-height: 1.1111111;
}

// Bold text within h1 headings
.pg-message-contents :where(h1 strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  font-weight: 900;
  color: inherit;
}

// Level 2 heading styles
.pg-message-contents :where(h2):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  font-weight: 700;
  font-size: 1.5em;
  margin-top: 2em;
  margin-bottom: 1em;
  line-height: 1.3333333;
}

// Bold text within h2 headings
.pg-message-contents :where(h2 strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  font-weight: 800;
  color: inherit;
}

// Level 3 heading styles
.pg-message-contents :where(h3):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  font-weight: 600;
  font-size: 1.25em;
  margin-top: 1.6em;
  margin-bottom: 0.6em;
  line-height: 1.6;
}

// Bold text within h3 headings
.pg-message-contents :where(h3 strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  font-weight: 700;
  color: inherit;
}

// Level 4 heading styles
.pg-message-contents :where(h4):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  font-weight: 600;
  margin-top: 1.5em;
  margin-bottom: 0.5em;
  line-height: 1.5;
}

// Bold text within h4 headings
.pg-message-contents :where(h4 strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  font-weight: 700;
  color: inherit;
}

// Image spacing and layout
.pg-message-contents :where(img):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

// Picture element container styling
.pg-message-contents :where(picture):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  display: block;
  margin-top: 2em;
  margin-bottom: 2em;
}

// Video element spacing
.pg-message-contents :where(video):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

// Keyboard shortcut styling
.pg-message-contents :where(kbd):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  font-weight: 500;
  font-family: inherit;
  color: var(--tw-prose-kbd);
  box-shadow: 0 0 0 1px rgb(var(--tw-prose-kbd-shadows) / 10%), 0 3px 0 rgb(var(--tw-prose-kbd-shadows) / 10%);
  font-size: 0.875em;
  border-radius: 0.3125rem;
  padding-top: 0.1875em;
  padding-inline-end: 0.375em;
  padding-bottom: 0.1875em;
  padding-inline-start: 0.375em;
}

// Inline code styling with backticks
.pg-message-contents :where(code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-code);
  font-weight: 600;
  font-size: 0.875em;
}

// Opening backtick for inline code
.pg-message-contents :where(code):not(:where([class~="not-prose"], [class~="not-prose"] *))::before {
  content: "`";
}

// Closing backtick for inline code
.pg-message-contents :where(code):not(:where([class~="not-prose"], [class~="not-prose"] *))::after {
  content: "`";
}

// Code styling within links - inherits link color
.pg-message-contents :where(a code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
}

// Code styling within h1 headings - inherits heading color
.pg-message-contents :where(h1 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
}

// Code styling within h2 headings - inherits color with slightly smaller font
.pg-message-contents :where(h2 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
  font-size: 0.875em;
}

// Code styling within h3 headings - inherits color with slightly reduced font size
.pg-message-contents :where(h3 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
  font-size: 0.9em;
}

// Code styling within h4 headings - inherits heading color
.pg-message-contents :where(h4 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
}

// Code styling within blockquotes - inherits blockquote color
.pg-message-contents :where(blockquote code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
}

// Code styling within table headers - inherits header color
.pg-message-contents :where(thead th code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
}

// Pre element styling for code blocks with syntax highlighting
.pg-message-contents :where(pre):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-pre-code);
  background-color: var(--tw-prose-pre-bg);
  overflow-x: auto;
  font-weight: 400;
  font-size: 0.875em;
  line-height: 1.7142857;
  margin-top: 1.7142857em;
  margin-bottom: 1.7142857em;
  border-radius: 0.375rem;
  padding-top: 0.8571429em;
  padding-inline-end: 1.1428571em;
  padding-bottom: 0.8571429em;
  padding-inline-start: 1.1428571em;
}

// Reset code styling within pre elements to prevent conflicts
.pg-message-contents :where(pre code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  background-color: transparent;
  border-width: 0;
  border-radius: 0;
  padding: 0;
  font-weight: inherit;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
}

// Remove opening quote content from code within pre elements
.pg-message-contents :where(pre code):not(:where([class~="not-prose"], [class~="not-prose"] *))::before {
  content: none;
}

// Remove closing quote content from code within pre elements
.pg-message-contents :where(pre code):not(:where([class~="not-prose"], [class~="not-prose"] *))::after {
  content: none;
}

// Table styling
.pg-message-contents :where(table):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  width: 100%;
  table-layout: auto;
  text-align: start;
  margin-top: 2em;
  margin-bottom: 2em;
  font-size: 0.875em;
  line-height: 1.7142857;
}

// Table header styling
.pg-message-contents :where(thead):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-th-borders);
}

// Table header cell styling
.pg-message-contents :where(thead th):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  font-weight: 600;
  vertical-align: bottom;
  padding-inline-end: 0.5714286em;
  padding-bottom: 0.5714286em;
  padding-inline-start: 0.5714286em;
}

// Table body row styling
.pg-message-contents :where(tbody tr):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-td-borders);
}

// Last table body row styling
.pg-message-contents :where(tbody tr:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-bottom-width: 0;
}

// Table body cell styling
.pg-message-contents :where(tbody td):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  vertical-align: baseline;
}

// Table footer styling
.pg-message-contents :where(tfoot):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-top-width: 1px;
  border-top-color: var(--tw-prose-th-borders);
}

// Table footer cell styling
.pg-message-contents :where(tfoot td):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  vertical-align: top;
}

// Figure styling
.pg-message-contents :where(figure > *):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

// Figure caption styling
.pg-message-contents :where(figcaption):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-captions);
  font-size: 0.875em;
  line-height: 1.4285714;
  margin-top: 0.8571429em;
}

// Typography color variable definitions
.pg-message-contents {
  --tw-prose-body: #374151;
  --tw-prose-headings: #111827;
  --tw-prose-lead: #4b5563;
  --tw-prose-links: #111827;
  --tw-prose-bold: #111827;
  --tw-prose-counters: #6b7280;
  --tw-prose-bullets: #d1d5db;
  --tw-prose-hr: #e5e7eb;
  --tw-prose-quotes: #111827;
  --tw-prose-quote-borders: #e5e7eb;
  --tw-prose-captions: #6b7280;
  --tw-prose-kbd: #111827;
  --tw-prose-kbd-shadows: 17 24 39;
  --tw-prose-code: #111827;
  --tw-prose-pre-code: #e5e7eb;
  --tw-prose-pre-bg: #1f2937;
  --tw-prose-th-borders: #d1d5db;
  --tw-prose-td-borders: #e5e7eb;
  --tw-prose-invert-body: #d1d5db;
  --tw-prose-invert-headings: #fff;
  --tw-prose-invert-lead: #9ca3af;
  --tw-prose-invert-links: #fff;
  --tw-prose-invert-bold: #fff;
  --tw-prose-invert-counters: #9ca3af;
  --tw-prose-invert-bullets: #4b5563;
  --tw-prose-invert-hr: #374151;
  --tw-prose-invert-quotes: #f3f4f6;
  --tw-prose-invert-quote-borders: #374151;
  --tw-prose-invert-captions: #9ca3af;
  --tw-prose-invert-kbd: #fff;
  --tw-prose-invert-kbd-shadows: 255 255 255;
  --tw-prose-invert-code: #fff;
  --tw-prose-invert-pre-code: #d1d5db;
  --tw-prose-invert-pre-bg: rgb(0 0 0 / 50%);
  --tw-prose-invert-th-borders: #4b5563;
  --tw-prose-invert-td-borders: #374151;
  font-size: 1rem;
  line-height: 1.75;
}

// Picture image styling
.pg-message-contents :where(picture > img):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

// List item styling
.pg-message-contents :where(li):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

// Ordered list item styling
.pg-message-contents :where(ol > li):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-inline-start: 0.375em;
}

// Unordered list item styling
.pg-message-contents :where(ul > li):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-inline-start: 0.375em;
}

// Paragraph within unordered list item styling
.pg-message-contents :where(.prose > ul > li p):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0.75em;
  margin-bottom: 0.75em;
}

// First paragraph within unordered list item styling
.pg-message-contents :where(.prose > ul > li > p:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.25em;
}

// Last paragraph within unordered list item styling
.pg-message-contents :where(.prose > ul > li > p:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-bottom: 1.25em;
}

// First paragraph within ordered list item styling
.pg-message-contents :where(.prose > ol > li > p:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.25em;
}

// Last paragraph within ordered list item styling
.pg-message-contents :where(.prose > ol > li > p:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-bottom: 1.25em;
}

// Nested lists styling
.pg-message-contents :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0.75em;
  margin-bottom: 0.75em;
}

// Description list styling
.pg-message-contents :where(dl):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}

// Description term styling
.pg-message-contents :where(dd):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0.5em;
  padding-inline-start: 1.625em;
}

// Element following horizontal rule styling
.pg-message-contents :where(hr + *):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
}

// Element following h2 styling
.pg-message-contents :where(h2 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
}

// Element following h3 styling
.pg-message-contents :where(h3 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
}

// Element following h4 styling
.pg-message-contents :where(h4 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
}

// First table header cell styling
.pg-message-contents :where(thead th:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-inline-start: 0;
}

// Last table header cell styling
.pg-message-contents :where(thead th:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-inline-end: 0;
}

// Table cell padding and spacing
.pg-message-contents :where(tbody td, tfoot td):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-top: 0.5714286em;
  padding-inline-end: 0.5714286em;
  padding-bottom: 0.5714286em;
  padding-inline-start: 0.5714286em;
}

// First table cell padding
.pg-message-contents :where(tbody td:first-child, tfoot td:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-inline-start: 0;
}

// Last table cell padding
.pg-message-contents :where(tbody td:last-child, tfoot td:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-inline-end: 0;
}

// Figure margins
.pg-message-contents :where(figure):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

// Remove top margin from first child
.pg-message-contents :where(.prose > :first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
}

// Remove bottom margin from last child
.pg-message-contents :where(.prose > :last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-bottom: 0;
}

// Fallback colors for browsers that don't support OKLCH
@supports not (color: oklch(0% 0 0)) {
  .pg-message-contents :where(code):not(:where([class~="not-prose"] *, pre *)) {
    background-color: var(--fallback-b3, oklch(var(--b3)/1));
  }
}

// Typography color variable definitions
:root .pg-message-contents {
  --tw-prose-body: var(--fallback-bc, oklch(var(--bc)/0.8));
  --tw-prose-headings: var(--fallback-bc, oklch(var(--bc)/1));
  --tw-prose-lead: var(--fallback-bc, oklch(var(--bc)/1));
  --tw-prose-links: var(--fallback-bc, oklch(var(--bc)/1));
  --tw-prose-bold: var(--fallback-bc, oklch(var(--bc)/1));
  --tw-prose-counters: var(--fallback-bc, oklch(var(--bc)/1));
  --tw-prose-bullets: var(--fallback-bc, oklch(var(--bc)/0.5));
  --tw-prose-hr: var(--fallback-bc, oklch(var(--bc)/0.2));
  --tw-prose-quotes: var(--fallback-bc, oklch(var(--bc)/1));
  --tw-prose-quote-borders: var(--fallback-bc, oklch(var(--bc)/0.2));
  --tw-prose-captions: var(--fallback-bc, oklch(var(--bc)/0.5));
  --tw-prose-code: var(--fallback-bc, oklch(var(--bc)/1));
  --tw-prose-pre-code: var(--fallback-nc, oklch(var(--nc)/1));
  --tw-prose-pre-bg: var(--fallback-n, oklch(var(--n)/1));
  --tw-prose-th-borders: var(--fallback-bc, oklch(var(--bc)/0.5));
  --tw-prose-td-borders: var(--fallback-bc, oklch(var(--bc)/0.2));
}

// Code block styling overrides
.pg-message-contents :where(code):not(:where([class~="not-prose"] *, pre *)) {
  padding: 1px 8px;
  border-radius: var(--rounded-badge);
  font-weight: initial;
  background-color: var(--fallback-bc, oklch(var(--bc)/0.1));
}

// Remove default backticks from code blocks
.pg-message-contents :where(code):not(:where([class~="not-prose"], [class~="not-prose"] *))::before,
.pg-message-contents :where(code):not(:where([class~="not-prose"], [class~="not-prose"] *))::after {
  display: none;
}

// Reset code block styling within pre elements
.pg-message-contents pre code {
  border-radius: 0;
  padding: 0;
}

// Table border colors
.pg-message-contents :where(tbody tr, thead):not(:where([class~="not-prose"] *)) {
  border-bottom-color: var(--fallback-bc, oklch(var(--bc)/0.2));
}

// Remove max-width constraint
.pg-message-contents {
  max-width: none;
}

// Styles for larger screens ------------------------------

@media (min-width: 1024px) {

  .pg-message-contents {
    font-size: 1.125rem;
    line-height: 1.7777778;
  }

  .pg-message-contents :where(p):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.3333333em;
    margin-bottom: 1.3333333em;
  }

  .pg-message-contents :where([class~="lead"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    font-size: 1.2222222em;
    line-height: 1.4545455;
    margin-top: 1.0909091em;
    margin-bottom: 1.0909091em;
  }

  .pg-message-contents :where(blockquote):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.6666667em;
    margin-bottom: 1.6666667em;
    padding-inline-start: 1em;
  }

  .pg-message-contents :where(h1):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    font-size: 2.6666667em;
    margin-top: 0;
    margin-bottom: 0.8333333em;
    line-height: 1;
  }

  .pg-message-contents :where(h2):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    font-size: 1.6666667em;
    margin-top: 1.8666667em;
    margin-bottom: 1.0666667em;
    line-height: 1.3333333;
  }

  .pg-message-contents :where(h3):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    font-size: 1.3333333em;
    margin-top: 1.6666667em;
    margin-bottom: 0.6666667em;
    line-height: 1.5;
  }

  .pg-message-contents :where(h4):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.7777778em;
    margin-bottom: 0.4444444em;
    line-height: 1.5555556;
  }

  .pg-message-contents :where(img):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.7777778em;
    margin-bottom: 1.7777778em;
  }

  .pg-message-contents :where(picture):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.7777778em;
    margin-bottom: 1.7777778em;
  }

  .pg-message-contents :where(picture > img):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 0;
    margin-bottom: 0;
  }

  .pg-message-contents :where(video):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.7777778em;
    margin-bottom: 1.7777778em;
  }

  .pg-message-contents :where(kbd):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    font-size: 0.8888889em;
    border-radius: 0.3125rem;
    padding-top: 0.2222222em;
    padding-inline-end: 0.4444444em;
    padding-bottom: 0.2222222em;
    padding-inline-start: 0.4444444em;
  }

  .pg-message-contents :where(code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    font-size: 0.8888889em;
  }

  .pg-message-contents :where(h2 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    font-size: 0.8666667em;
  }

  .pg-message-contents :where(h3 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    font-size: 0.875em;
  }

  .pg-message-contents :where(pre):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    font-size: 0.8888889em;
    line-height: 1.75;
    margin-top: 2em;
    margin-bottom: 2em;
    border-radius: 0.375rem;
    padding-top: 1em;
    padding-inline-end: 1.5em;
    padding-bottom: 1em;
    padding-inline-start: 1.5em;
  }

  .pg-message-contents :where(ol):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.3333333em;
    margin-bottom: 1.3333333em;
    padding-inline-start: 1.5555556em;
  }

  .pg-message-contents :where(ul):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.3333333em;
    margin-bottom: 1.3333333em;
    padding-inline-start: 1.5555556em;
  }

  .pg-message-contents :where(li):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 0.6666667em;
    margin-bottom: 0.6666667em;
  }

  .pg-message-contents :where(ol > li):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-inline-start: 0.4444444em;
  }

  .pg-message-contents :where(ul > li):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-inline-start: 0.4444444em;
  }

  .pg-message-contents :where(.lg\:prose-lg > ul > li p):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 0.8888889em;
    margin-bottom: 0.8888889em;
  }

  .pg-message-contents :where(.lg\:prose-lg > ul > li > p:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.3333333em;
  }

  .pg-message-contents :where(.lg\:prose-lg > ul > li > p:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-bottom: 1.3333333em;
  }

  .pg-message-contents :where(.lg\:prose-lg > ol > li > p:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.3333333em;
  }

  .pg-message-contents :where(.lg\:prose-lg > ol > li > p:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-bottom: 1.3333333em;
  }

  .pg-message-contents :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 0.8888889em;
    margin-bottom: 0.8888889em;
  }

  .pg-message-contents :where(dl):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.3333333em;
    margin-bottom: 1.3333333em;
  }

  .pg-message-contents :where(dt):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.3333333em;
  }

  .pg-message-contents :where(dd):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 0.6666667em;
    padding-inline-start: 1.5555556em;
  }

  .pg-message-contents :where(hr):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 3.1111111em;
    margin-bottom: 3.1111111em;
  }

  .pg-message-contents :where(hr + *):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 0;
  }

  .pg-message-contents :where(h2 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 0;
  }

  .pg-message-contents :where(h3 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 0;
  }

  .pg-message-contents :where(h4 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 0;
  }

  .pg-message-contents :where(thead th:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-inline-start: 0;
  }

  .pg-message-contents :where(thead th:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-inline-end: 0;
  }

  .pg-message-contents :where(tbody td, tfoot td):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-top: 0.75em;
    padding-inline-end: 0.75em;
    padding-bottom: 0.75em;
    padding-inline-start: 0.75em;
  }

  .pg-message-contents :where(tbody td:first-child, tfoot td:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-inline-start: 0;
  }

  .pg-message-contents :where(tbody td:last-child, tfoot td:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-inline-end: 0;
  }

  .pg-message-contents :where(figure):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.7777778em;
    margin-bottom: 1.7777778em;
  }

  .pg-message-contents :where(figure > *):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 0;
    margin-bottom: 0;
  }

  .pg-message-contents :where(figcaption):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    font-size: 0.8888889em;
    line-height: 1.5;
    margin-top: 1em;
  }

  .pg-message-contents :where(.lg\:prose-lg > :first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 0;
  }

  .pg-message-contents :where(.lg\:prose-lg > :last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-bottom: 0;
  }
}