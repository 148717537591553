@use "../../base/styles/base/_gf-typography.scss";

.gf-dashboard {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;

    &--empty {
        @extend .gf-dashboard;
        max-width: 48rem;
        align-items: center;
        text-align: center;
    }

    &--pinned {
        @extend .gf-dashboard;
        height: 100%;

        &--header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            max-width: 1400px;
            margin: 0 auto;
            width: 100%;

            &--team {
                @extend .gf-semibold;
                display: flex;
                align-items: center;
                gap: 1.2rem;
                flex: 0 0 200px;

                >img {
                    width: 4rem;
                    height: 4rem;
                }
            }

            &--name {
                @extend .gf-semibold;
                // Commented out to align dashboard title in homepage view
                // flex: 1;
                text-align: center;
                min-width: 0;

                >h3 {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;

                    >span {
                        color: var(--gf-neutral-500);
                    }
                }
            }

            .self-end {
                flex: 0 0 200px;
                justify-content: flex-end;
                display: flex;
                align-items: center;

                img {
                    max-height: 35px;
                }

                span {
                    margin-right: 0.5rem;
                    font-weight: bold;
                }
            }
        }

        &--canvas {
            height: 100%;
            overflow-y: auto;
        }
    }
}

// Used for shared dashboards
.gf-body--dashboard--public {
    height: 100vh;
    padding: 2.4rem;
}

.gf-dashboard--detail--content {
    height: 100%;
}

// Media queries for responsive design
@media only screen and (max-width: 768px) {
    .gf-dashboard--pinned {
        &--header {
            flex-direction: column;
            align-items: center;
            gap: 1rem;
            padding: 1rem;

            &--team,
            &--name,
            .self-end {
                flex: none;
                width: 100%;
                justify-content: center;
                text-align: center;
            }

            &--name h3 {
                white-space: normal;
                margin: 0.5rem 0;
            }
        }
    }
}

// Widget canvas responsive styles
@media only screen and (max-width: var(--dashboard-width)) {
    .widgets,
    .widgets__canvas {
        width: 100%;
        padding: 0;
    }
}