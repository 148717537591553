.placeholder-scr {
  align-items: center;
  display: flex;
  flex-flow: column;
  font-size: 1.4rem;
  height: 100%;
  justify-content: center;
  min-height: 18rem;

  &__icon {
    margin-bottom: 1rem;
  }

  &__title {
    color: var(--c-black-50);
  }

  &__subtext {
    color: var(--c-fig-secondary);
    text-decoration: underline;
  }

  &--fillscreen {
    width: 100%;
  }

  &--overlay {
    background: rgba(black, 0.1);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &--inline {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}
