.gf-combobox {
    position: relative;
    width: 100%;

    &--dropdown {
        position: absolute;
        width: 100%;
        max-height: 24rem;
        overflow-y: auto;
        background-color: white;
        border-radius: 0 0 .8rem .8rem;
        cursor: pointer;
        z-index: 100;

        &--list {
            border: 1px solid var(--gf-neutral-200);

            &--option {
                padding: 1.2rem;

                &:hover {
                    background-color: var(--gf-neutral-50);
                }

                &--empty {
                    @extend .gf-combobox--dropdown--list--option;
                    cursor: default;
                    color: var(--gf-neutral-500);
                }

                &--create {
                    @extend .gf-combobox--dropdown--list--option;
                }
            }
        }
    }
}

#node-update-form .gf-combobox--dropdown--list--option {
    @extend .gf-combobox--dropdown--list--option;
}