@use "../base/gf-typography" as *;
@use "../layout/gf-layout" as *;

/*=============================================
=             Go Fig Modal Styles             =
=============================================*/

/*=============== Base Modal ================*/

.gf-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

    &--close {
        position: absolute;
        top: 2rem;
        right: 2rem;
        width: 1.6rem;
        height: 1.6rem;
        cursor: pointer;
        z-index: 1;

        >img {
            width: 100%;
            height: 100%;
        }
    }

    &--content {
        position: relative;
        width: 100%;
        max-width: 56rem; // Default size
        max-height: 90vh;
        background: var(--gf-generic-white);
        border-radius: 2.4rem;
        overflow: hidden; // Important for scrolling content
        display: flex;
        flex-direction: column;
        animation: modal-fade-in 0.2s ease-out;

        &--container {
            flex: 1;
            overflow-y: auto;
            padding: 8rem;
        }
    }

    &--small {
        @extend .gf-modal;

        .gf-modal--content {
            max-width: map-get($container-sizes, "small");
        }
    }

    &--medium {
        @extend .gf-modal;

        .gf-modal--content {
            max-width: map-get($container-sizes, "medium");
        }
    }

    &--large {
        @extend .gf-modal;

        .gf-modal--content {
            max-width: map-get($container-sizes, "large");
        }
    }

    &--xlarge {
        @extend .gf-modal;

        .gf-modal--content {
            max-width: map-get($container-sizes, "xlarge");
        }
    }

    &--full {
        @extend .gf-modal;

        .gf-modal--content {
            width: 100%;
            height: 100%;
            max-width: calc(100vw - 8rem);
            max-height: calc(100vh - 8rem);
        }
    }
}

@keyframes modal-fade-in {
    from {
        opacity: 0;
        transform: scale(0.95);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}

/*=============== Base Modal ================*/

.gf-modal {
    // Modal for displaying connector create form
    // after OAuth authorization
    &--connector {
        width: 100%;
        height: 100%;
        display: flex;
        overflow-y: auto;

        &--content {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 2.4rem;

            .gf-modal--connector--logos {
                display: flex;

                .gf-modal--connector--logo {
                    width: 120px;
                    height: 120px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex: 1;
                }
            }

            .gf-form--connector--create {
                max-width: 600px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .gf-form--fields {
                    width: 400px;
                    display: flex;
                    flex-direction: column;
                    gap: 1.6rem;

                    .form-group {
                        display: flex;
                        flex-direction: column;
                        gap: .4rem;
                    }

                }
            }
        }
    }

    &--instructions {
        display: flex;
        flex-direction: column;
        gap: 1.6rem;

        ul {
            list-style-type: disc;
            padding-left: 20px;
        }
    }

    // - Modal for selecting a connector
    &--select {
        max-width: 1200px;
        height: 100%;
        // padding: 4rem;
        margin: 0 auto;
        // background: white;
        // border-radius: 0.8rem;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        gap: 2rem;

        .connector-search {
            margin: 0 auto;
            max-width: 600px;
            position: relative;
            flex-shrink: 0;

            // &::before {
            //     content: '\f002';
            //     font-family: 'Font Awesome 5 Pro';
            //     position: absolute;
            //     left: 1.6rem;
            //     top: 50%;
            //     transform: translateY(-50%);
            //     color: var(--gf-neutral-500);
            //     font-size: 1.4rem;
            // }

            input {
                width: 100%;
                padding: 1.4rem 1.6rem 1.4rem 4rem;
                color: var(--gf-neutral-500);
                border: 1px solid var(--color-border);
                border-radius: 0.8rem;
                font-size: 1.4rem;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
                transition: all 0.2s ease;

                &:focus {
                    outline: none;
                    border-color: var(--color-primary);
                    box-shadow: 0 0 0 3px rgba(var(--color-primary-rgb), 0.1);
                    background-color: var(--gf-neutral-100);
                }
            }
        }

        .category-tabs {
            display: flex;
            justify-content: center;
            gap: 1rem;
            flex-shrink: 0;

            .category-tab {
                @extend .gf-label--medium;
                @extend .gf-semibold;
                padding: 1rem 2rem;
                background: var(--gf-neutral-100);
                cursor: pointer;
                border-radius: 0.6rem;
                transition: all 0.2s ease;
                box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

                &:hover {
                    background: var(--color-background-hover);
                    border-color: var(--color-border-hover);
                    transform: translateY(-1px);
                }

                &.active {
                    background: var(--c-fig-secondary);
                    color: white;
                    border-color: var(--c-fig-secondary);
                    box-shadow: 0 2px 4px rgba(107, 70, 193, 0.2);

                    &:hover {
                        background: var(--c-fig-secondary-50);
                        border-color: var(--c-fig-secondary-50);
                    }
                }
            }
        }

        &--grid {
            flex: 1;
            min-height: 0; // For nested scrollable content
            overflow: hidden;
            position: relative;

            .connector-grid {
                height: 100%;
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
                gap: 1.2rem;
                justify-content: center;
                overflow-y: auto;
                padding: 2.4rem 0;
                min-height: 0;
                overflow-x: hidden;

                &::-webkit-scrollbar {
                    width: 8px;
                }

                &::-webkit-scrollbar-track {
                    background: var(--gf-neutral-100);
                    border-radius: 4px;
                }

                &::-webkit-scrollbar-thumb {
                    background: var(--gf-neutral-300);
                    border-radius: 4px;

                    &:hover {
                        background: var(--gf-neutral-400);
                    }
                }
            }
        }

        .connector-card {
            background: white;
            border-radius: 0.8rem;
            padding: 2rem;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 1.5rem;
            transition: transform 0.2s, box-shadow 0.2s;
            min-height: 200px;
            text-decoration: none;
            color: inherit;
            cursor: pointer;

            &:hover {
                transform: translateY(-2px);
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            }

            img {
                width: 120px;
                height: 120px;
                object-fit: contain;
            }

            h3 {
                margin: 0;
                font-size: 1.6rem;
                font-weight: 500;
                color: var(--color-text);
            }

            p {
                margin: 0;
                font-size: 1.6rem;
                color: var(--color-text);
                line-height: 1.4;
            }

            ul {
                margin: 10px 0;
                padding-left: 2rem;
                font-size: 1.6rem;
                list-style-type: disc;
                color: var(--color-text);
                line-height: 1.4;
            }

            &[data-hidden="true"] {
                display: none !important;
            }
        }

        &--footer {
            padding: 1.2rem 0;
            margin-bottom: 1.6rem;
            flex-shrink: 0;
            text-align: center;
        }
    }
}