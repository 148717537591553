@use "../../base/styles/layout/gf-layout";

.gf-settings {
    @extend .gf-container--full-width;
    display: flex;
    flex-direction: row;

    &--content {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;

        &--container {
            @extend .gf-container--small;
            flex-direction: column;

            section {
                h2, h3, h4, h5, h6 {
                    margin-bottom: 1.6rem;
                }

                form {
                    width: 100%;

                    .form-group {
                        width: 100%;
                        margin-bottom: 1.6rem;

                        label {
                            display: block;
                            margin-bottom: 0.8rem;
                        }

                        input,
                        textarea,
                        select,
                        button {
                            width: 100%;
                            padding: 0.8rem;
                        }

                        textarea {
                            min-height: 100px;
                        }
                    }

                    button {
                        width: 100%;
                    }
                }
            }
        }
    }
}