@use '../utilities/mixins';

.widget-card {
  display: flex;
  position: relative;

  &__visual {
    display: flex;
    flex-flow: column;
    flex-grow: 1;
    overflow: hidden;
    width: 100%;
    z-index: calc(var(--z-default) + 1);

    &--border {
      border: 1px solid var(--c-gray);
    }

    &--center {
      justify-content: center;
      align-items: center;
    }
  }

  &__commands {
    display: none;
    background-color: var(--c-white);
    border: 1px solid var(--c-gray);
    bottom: 1.6rem;
    color: var(--c-black);
    align-items: center;
    flex-flow: row;
    font-size: 1.6rem;
    right: 1.6rem;
    position: absolute;
    z-index: calc(var(--z-ui) + 1);

    .widget-card__button:hover,
    a:hover {
      color: var(--c-black);
    }

    i {
      display: block;
    }

    .widget-card__button {
      box-sizing: content-box;
      padding: 0.9rem 0.7rem;

      &:hover {
        background: var(--c-gray-50);
      }
    }
  }

  &__date-range-indicator {
    display: none;
    position: absolute;
    top: 1.6rem;
    right: 1.6rem;
    z-index: calc(var(--z-ui) + 1);
  }

  &:focus-within &__commands,
  &:hover &__commands,
  &:hover &__date-range-indicator {
    display: flex;
  }

  [data-focused~='true'] &__indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: var(--z-ui);

    span {
      position: absolute;
      width: 0.4rem;
      height: 0.4rem;
      background: var(--c-black-20);
      z-index: var(--z-ui);
    }
  }
}

gy-widget {
  display: block;
  position: relative;
}

.widget-update-sidebar {
  border-right: 1px solid var(--c-gray);
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  overflow: hidden;
  position: relative;

  &__form {
    flex: 1 1 0%;
    gap: 0;
    overflow: hidden;
  }

  #widget-editable-name {
    padding: 1.6rem;
    border-bottom: 1px solid var(--c-gray);
  }

  .widget-update__footer {
    display: flex;
    gap: 1.6rem;
    padding: 1.6rem;
    width: 100%;
    background: var(--c-white);
    margin-top: auto;
    border-top: 1px solid var(--c-gray);
  }

  .widget-update__body {
    display: flex;
    flex-direction: column;
    flex: 1 1 0%;
    overflow-y: auto;
    width: 100%;
    gap: 1.25rem;
    padding: 1.6rem;
  }
}
