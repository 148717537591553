.upload-csv {
  margin: 0 1.6rem 1.6rem;
  border-radius: 4px;

  // Generated using: https://kovart.github.io/dashed-border-generator/
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%23e6e6e6' stroke-width='4' stroke-dasharray='16' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");

  &__form {
    width: 36rem;
    max-width: 36rem;
  }
}
