@use "../../base/styles/base/gf-typography" as *;

.formula {
  height: 100%;
  overflow: auto;

  width: 100%;
  position: relative;

  &__content {
    padding: 1.6rem;
    display: flex;
    flex-flow: column;
    gap: 1.6rem;
  }

  &__drawer {
    position: sticky;
    width: 100%;
    height: auto;
    bottom: 0;
    background-color: var(--c-white);
    padding: 4rem;
    border-top: 1px solid var(--c-gray);

    transition: bottom 500ms;

    &.closed {
      display: none;
    }

    .function-info {
      display: flex;
      flex-flow: column;
      gap: 1.2rem;

      &__header {
        display: flex;
        gap: 1.2rem;
      }
    }
  }

  em {
    color: var(--c-black-20);
  }

  ul {
    list-style-type: disc;
    padding-left: 2rem;
  }
}

.category {
  display: flex;
  width: 100%;

  &__pill {
    border: 1px solid var(--c-gray);
    padding: 0.6rem 0.8rem 0.6rem 0.8rem;
    text-transform: capitalize;
    flex: 1 1 0%;
    color: var(--c-black-50);

    &+& {
      border-left: none;
    }

    &:hover {
      background-color: var(--c-gray-50);
    }
  }

  &__selected {
    border-bottom: 2px solid var(--c-fig-secondary);
    font-weight: 500;
    color: var(--c-black);
  }
}

.functions {
  padding: 1.2rem;
  background-color: var(--gf-neutral-100);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.6rem;

  .function-pill {
    padding: 1.2rem 2rem;
    background-color: var(--gf-neutral-50);
    border: 1px solid var(--c-gray);
    border-radius: .8rem;
    display: flex;
    flex-flow: column;
    justify-content: center;
    cursor: pointer;

    &:hover {
      border: 1px solid var(--c-fig-secondary-50);
      background-color: var(--c-gray-10);
    }

    em {
      color: var(--c-black-20);
    }

    i {
      margin-right: 0.8rem;
    }
  }
}

.CodeMirror-sizer {
  .CodeMirror-lines {
    padding: 1.2rem .8rem;
  }
}

.gf-formula {

  // &--functions {
  //   display: flex;
  //   flex-flow: column;
  //   gap: 1.2rem;
  // }

  &--header {
    @include gf-font-weight("bold");
    display: flex;
    gap: 1.2rem;
    align-items: center;

    h4 {
      >i {
        margin-right: 0.8rem;
      }
    }
  }
}