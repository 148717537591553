.account {
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  padding: 1.6rem;
  gap: 1.6rem;

  &__billing {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    max-width: 65rem;
  }

  &__title {
    h2 {
      margin-right: auto;
      font-weight: 500;

      span {
        color: var(--c-fig-secondary);
      }
    }
  }

  &__plan {
    display: flex;
    flex-flow: row wrap;

    > div {
      align-items: center;
      display: flex;
      flex-direction: column;
      flex: 1 1 0;
      padding: 0.8rem 0;
      justify-content: center;
    }

    .w-full {
      border-bottom: 1px solid var(--c-gray);
      flex: 1 0 100%;
      padding: 1.6rem 0;
    }

    .w-1\/2 {
      border-bottom: 1px solid var(--c-gray);
      flex: 1 0 50%;
      padding: 1.6rem 0;

      & + .w-1\/2 {
        border-left: 1px solid var(--c-gray);
      }
    }

    .w-1\/3 {
      border-bottom: 1px solid var(--c-gray);
      flex: 1 0 33.33%;
      padding: 1.6rem 0;
    }

    footer {
      display: grid;
      width: 100%;
      padding: 0.8rem 0;
      justify-items: center;
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
