@use '../utilities' as u;
@use '../base/gf-typography' as *;

%input {
  background-color: var(--c-white);
  border: 1px solid var(--c-gray);
  border-radius: 5px;
  color: var(--c-black);
  font-size: 1.4rem;
  padding: 1.2rem 0.8rem;
  width: 100%;

  &:focus {
    border-color: var(--c-fig-secondary);
    color: var(--c-black);
    outline: none;
  }

  &[disabled],
  &--disabled,
  &.disabled {
    background-color: var(--c-gray-50);
    color: var(--c-black-50);
    cursor: not-allowed;
  }
}

input[type='submit'] {
  cursor: pointer;
}

input[type='text'] {
  @extend %input;
}

input[type='email'] {
  @extend %input;
}

.password-input {
  position: relative;

  @include u.fa-icon {
    color: var(--c-black);
    display: inline;
    position: absolute;
    right: 0.8rem;
    top: 50%;
    transform: translateY(-50%);
  }

  [type='password'] ~ .fa-fw::after {
    content: '\f070';
  }

  [type='text'] ~ .fa-fw::after {
    content: '\f06e';
  }
}

input[type='password'] {
  @extend %input;
}

input[type='url'] {
  @extend %input;
}

input[type='file'] {
  @extend %input;

  & ~ img {
    border: 1px solid var(--c-transparent);
    border-radius: 5px;
    height: 5.4rem;
    min-height: 5.4rem;
    min-width: 5.4rem;
    width: 5.4rem;
  }
}

input[type='number'] {
  @extend %input;
}

input[type='time'] {
  @extend %input;
}

input[type='date'] {
  @extend %input;
}

input[type='datetime-local'] {
  @extend %input;
}

input[type='search'] {
  @extend %input;
}

input[type='color'] {
  @extend %input;

  border: 1px solid var(--c-gray);
  height: 4.3rem;
  padding: 0;
  width: 100%;

  &::-webkit-color-swatch-wrapper {
    padding: 0;
  }

  // Note for some reason SCSS is not playing well with nested pseudo-elements,
  // do not nest/merge these or the styling breaks!
  &::-webkit-color-swatch {
    border: none;
    border-radius: 5px;
  }

  &::-moz-color-swatch {
    border: none;
    border-radius: 5px;
  }
}

textarea {
  @extend %input;

  height: 4.3rem;
  min-height: 4.3rem;
}

select {
  @extend %input;

  &:invalid {
    color: var(--c-black-50);
  }

  option:first-child {
    color: var(--c-black-50);
  }
}

.label {
  color: var(--c-black);
  display: flex;
  flex-flow: column;
  flex: 1 1 100%;
  font-size: 1.4rem;
  gap: 0.4rem;
  line-height: 1.6rem;
  position: relative;

  &:focus-within,
  &:focus-within label {
    color: var(--c-fig-secondary);
  }

  &.label--row {
    flex-flow: row;
    align-items: center;
  }

  &.label--half {
    flex: 2 2 45%;
  }

  &.label--third {
    flex: 1 1 25%;
  }

  ul li {
    padding: 0.4rem 0;
  }

  .button-input {
    display: flex;

    input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .button {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      height: 4.3rem;
      max-height: 4.3rem;
      align-items: center;
    }
  }

  > input,
  select {
    margin-top: 0.3rem;
  }
}

.label__unit {
  align-items: center;
  background-color: var(--c-gray-20);
  border: 1px solid var(--c-gray);
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  border-left: 0;
  color: var(--c-black-50);
  display: flex;
  font-weight: 400;
  justify-content: center;
  padding: 0 0.6rem;
  width: min-content;
}

.label__unit + input {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.errorlist {
  color: var(--gf-destructive-500);
  @extend .gf-label--small;

  >li {
    margin-top: .4rem;
  }
}

input.input,
.input {
  @extend %input;

  &__inline {
    border: none;
    color: var(--c-black);
    font-size: 1.8rem;
    font-weight: 500;
    padding: 0.4rem;

    // &:hover {
    //   background-color: var(--c-gray-50);
    // }
  }

  &__ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__font-inherit {
    font-size: inherit;
    font-weight: inherit;
  }

  &__contenteditable {
    background-color: transparent;
    border: none;
    font-size: 1.6rem;
    font-weight: 500;
    outline: none;
    text-align: center;
  }

  &__help-text {
    color: var(--c-black-50);
    display: block;
    font-size: 1.2rem;
    line-height: 1.4rem;
    margin: 0.2rem 0;
  }

  &__toggle {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 0.8rem;
  }

  &--sm {
    padding: 0.4rem;
  }
}

label.checkbox {
  align-items: center;
  border-radius: .8rem;
  border: 1px solid var(--gf-neutral-200);
  // color: var(--c-black);
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 1.2rem;
  margin-top: 0.4rem;
  padding: 1.2rem 1.6rem;

  &::before {
    color: var(--gf-neutral-200);
    content: '\f0c8';
    font-family: 'Font Awesome 5 Pro';
    font-size: 1.8rem;
    font-weight: 500;
    // margin-left: auto;
  }

  &:hover {
    border-color: var(--gf-neutral-300);
    background-color: var(--gf-neutral-50);
    // color: var(--gf-primary-500);

    &::before {
      content: '\f14a';
    }
  }

  &--radio::before {
    content: '\f111';
  }

  &--icon {
    height: 4.8rem;
  }

  &--checked {
    border: 1px solid var(--gf-neutral-700);
    // color: var(--c-black);

    i {
      color: var(--gf-neutral-700);
    }

    // Radio checkbox
    &::before {
      color: var(--gf-neutral-700);
      content: '\f14a';
    }
  }

  &--checked.checkbox--radio::before,
  &--radio:hover::before {
    content: '\f192';
  }
}

// based on https://www.sarasoueidan.com/blog/inclusively-hiding-and-styling-checkboxes-and-radio-buttons/
.checkbox__hidden {
  left: -100vw;
  position: absolute;
  top: 0;
  visibility: hidden;

  // Inspired by https://css-tricks.com/the-checkbox-hack/
  &:checked + label.checkbox__enabled {
    border: 1px solid var(--c-fig-secondary);

    &::before {
      color: var(--c-fig-secondary);
      content: '\f14a';
    }
  }

  &:checked + label.checkbox__disabled {
    border: 1px solid var(--c-gray);

    &::before {
      color: var(--c-gray);
      content: '\f14a';
    }
  }

  &:checked + label.checkbox__active {
    border: 1px solid var(--c-blue-50);
    cursor: default;

    &::before {
      color: var(--c-blue-50);
      content: '\f14a';
    }
  }
}

// Previous implementation for toggle slider
// .toggle {
//   display: flex;
//   height: 2.8rem;
//   position: relative;

//   input {
//     float: left;
//     height: 0;
//     opacity: 0;
//     position: absolute;
//     width: 0;
//   }

//   input:checked + &__slider {
//     border-color: var(--c-fig-secondary);
//   }

//   input:checked + &__slider::before {
//     left: calc(100% - 1.6rem - 0.4rem);
//     background-color: var(--c-fig-secondary);
//   }

//   &__slider {
//     background-color: var(--c-white);
//     border: 1px solid var(--c-gray);
//     display: inline-block;
//     height: 100%;
//     width: 4.4rem;
//     position: relative;
//     border-radius: 15px;
//   }

//   &__slider::before {
//     background-color: var(--c-gray);
//     border-radius: inherit;
//     content: '';
//     display: block;
//     height: 1.6rem;
//     left: 0.4rem;
//     position: absolute;
//     top: 50%;
//     transform: translateY(-50%);
//     transition: left 0.125s ease, color 0.075s ease;
//     width: 1.6rem;
//   }
// }

// Refactored implementation for toggle slider
.toggle {
  --toggle-width: 4.4rem;
  --toggle-height: 2.8rem;
  --slider-size: 2rem;
  --slider-margin: 0.4rem;
  --border-width: 1px;

  display: flex;
  align-items: center;
  height: var(--toggle-height);
  position: relative;

  &__input {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    z-index: 2;
  }

  &__slider {
    position: relative;
    display: inline-block;
    width: var(--toggle-width);
    height: var(--toggle-height);
    background-color: var(--c-white);
    border: var(--border-width) solid var(--c-gray);
    border-radius: calc(var(--toggle-height) / 2);
    pointer-events: none;
  }

  &__slider-inner {
    position: absolute;
    content: '';
    height: var(--slider-size);
    width: var(--slider-size);
    left: var(--slider-margin);
    top: 50%;
    transform: translateY(-50%);
    background-color: var(--c-gray);
    border-radius: 50%;
    transition: 0.4s;
  }

  &__input:checked + &__slider &__slider-inner {
    transform: translate(calc(var(--toggle-width) - var(--slider-size) - var(--slider-margin) * 2 - var(--border-width)), -50%);
    background-color: var(--c-fig-secondary);
  }

  &__input:checked + &__slider {
    border-color: var(--c-fig-secondary);
  }

  &__label {
    margin-left: 0.8rem;
    cursor: pointer;
  }
}

.input-group {
  display: flex;
  flex-flow: row wrap;
  gap: 1.4rem;
  width: 100%;

  & + & {
    margin-top: 1.6rem;
  }
}
