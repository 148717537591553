@use "../utilities/_gf-utilities.scss" as *;

@mixin gf-scrollbar($width: 8px,
    $track-color: var(--gf-neutral-100),
    $thumb-color: var(--gf-neutral-300),
    $thumb-hover-color: var(--gf-neutral-400)) {

    // Firefox
    scrollbar-width: auto;
    scrollbar-color: $thumb-color $track-color;

    // Chrome, Safari, Edge
    &::-webkit-scrollbar {
        width: $width;
    }

    &::-webkit-scrollbar-track {
        // @extend .gf-border-radius--small;
        border-radius: 4px;
        background: $track-color;
    }

    &::-webkit-scrollbar-thumb {
        // @extend .gf-border-radius--small;
        border-radius: 4px;
        background: $thumb-color;

        &:hover {
            background: $thumb-hover-color;
        }
    }
}

@mixin gf-scrollbar-hidden {
    -ms-overflow-style: none; // IE and Edge
    scrollbar-width: none; // Firefox

    &::-webkit-scrollbar { // Chrome, Safari, and Opera
        display: none;
    }
}

.gf-scrollbar-hidden {
    @include gf-scrollbar-hidden;
}
