/*=============================================
=            Go Fig Layout Styles            =
=============================================*/

/*============ Content Containers ===========*/

$container-sizes: (
  "small": 600px,
  "medium": 800px,
  "large": 1000px,
  "xlarge": 1200px,
  "xxlarge": 1400px,
  "full-width": 100%
);

@mixin gf-container($width) {
  width: 100%;
  height: 100%;
  max-width: $width;
  padding: 2.4rem;
  display: flex;
  // flex-direction: column;
  gap: 2rem;
}

@each $size, $width in $container-sizes {
  .gf-container--#{$size} {
    @include gf-container($width);
  }
}

/*================ Icon Sizes ===============*/

$icon-sizes: (
  "small": 2rem,
  "medium": 2.4rem,
  "large": 4rem,
);

@mixin gf-icon($size) {
  width: $size;
  height: $size;
}

@each $size, $width in $icon-sizes {
  .gf-icon--#{$size} {
    @include gf-icon($size);
  }
}