@import '../../base/styles/components/_gf-sidebar.scss';

.gf-landing {
    height: 100%;
    background-color: var(--gf-primary-100);
    display: flex;
    flex-direction: column;

    &--left-panel {
        width: 40%;
        height: 100%;
        padding: 12rem;
        background-color: var(--gf-primary-50);
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 2.8rem;

        .gf-landing--content {
            display: flex;
            flex-direction: column;
            gap: 2.4rem;
        }
    }

    &--right-panel {
        width: 50%;
        background-color: var(--gf-primary-100);
    }

    .gf-login-form {
        button {
            width: 100%;
            color: var(--gf-generic-white);
            background-color: var(--gf-primary-500);
            border-radius: 0.8rem;
            padding: 1.4rem 2rem;
            margin: 1.2rem 0 2.4rem 0;
        }

        > div {
            width: 100%;
        }
    }

    footer {
        align-self: center;
    }

    .gf-sidebar--landing {
        @extend .gf-sidebar;
    }
}