@use "../base/gf-typography";

.gf-badge--icon--round {
    @extend .gf-label--xsmall;
    color: white;
    background-color: var(--gf-sky-500);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
}

.gf-badge--status {
    @extend .gf-paragraph--xsmall;
    border-radius: 1.6rem;
    padding: 0.4rem 0.8rem;

    &--success {
        @extend .gf-badge--status;
        color: var(--gf-success-500);
        background-color: var(--gf-success-50);

    }

    &--warning {
        @extend .gf-badge--status;
        color: var(--gf-warning-500);
        background-color: var(--gf-warning-50);
    }

    &--failed {
        @extend .gf-badge--status;
        color: var(--gf-destructive-500);
        background-color: var(--gf-destructive-50);
        cursor: pointer;
    }
}