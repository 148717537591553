@use "../../base/styles/base/_gf-typography.scss" as *;

.dashboard-sidebar {
  background-color: var(--gf-neutral-50);
  border-left: 1px solid var(--gf-neutral-200);
  display: flex;
  flex-flow: column;
  overflow: auto;
  width: 28rem;
  z-index: var(--z-default);

  &__item {
    @extend .gf-label--medium;
    color: var(--gf-neutral-600);
    align-items: center;
    cursor: grab;
    display: flex;
    gap: 1rem;
    height: 3.2rem;
    padding: .8rem;

    .fa-grip-vertical {
      color: var(--gf-neutral-400);
    }

    &:hover {
      background: var(--gf-neutral-100);
      border-radius: 4px;
    }
  }

  &__icon {
    color: var(--gf-primary-400);
  }

  &__name {
    flex: 1 1 0%;
  }
}

.gf-sidebar--dashboard--category {
  padding: 2rem;

  &--title {
    @extend .gf-semibold;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &--items {
    padding-top: 1.2rem;
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
  }
}