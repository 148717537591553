.dnd-sidebar {
  background-color: var(--c-white);
  border-left: 1px solid var(--c-gray);
  display: flex;
  flex-flow: column;
  overflow: auto;
  width: 42rem;
  z-index: var(--z-default);

  &__header {
    background-color: var(--c-white);
    border-bottom: 1px solid var(--c-gray);
    display: flex;
    gap: 1.6rem;
    position: sticky;
    padding: 0.8rem;
    top: 0;
    z-index: var(--z-ui);

    > .dnd-sidebar__node {
      padding: 0.8rem;
      margin: 0;
      min-height: 0;
    }

    > .dnd-sidebar__node:first-of-type i {
      color: var(--c-blue-50);
    }

    > .dnd-sidebar__node:last-of-type i {
      color: var(--c-fig-accent);
    }
  }

  &__node {
    // margin-top: 1.2rem;
    align-items: center;
    cursor: grab;
    display: grid;
    flex-direction: row;
    font-size: 1.4rem;
    gap: 1rem;
    grid-template-columns: 1fr 6fr;
    min-height: 6.8rem;

    &:hover {
      background: var(--c-fig-secondary-10);
      border-radius: 5px;
    }
  }

  &__icon {
    color: var(--c-fig-secondary);
    padding: 1rem;
    width: 3.6rem;
    height: 3.6rem;
  }

  &__description {
    margin-top: 0.8rem;
    color: var(--c-black-50);
  }
}
