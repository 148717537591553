$colors: (
  // FIG
  'fig-primary': #efefef,
  'fig-primary-500': #a7a7a7,
  'fig-primary-10': #f7f7f7,
  'fig-secondary': #3f2946,
  'fig-secondary-50': #62426c,
  'fig-secondary-20': #a374b1,
  'fig-secondary-10': #e4b2f4,
  'fig-accent': #568258,
  'fig-accent-500': #2E4C41,
  'fig-accent-20': #7cb47f,
  'fig-accent-10': #abe0ad,
  // 'fig-accent': #b46f95,
  // 'fig-accent-500': #4c0b2f,
  // 'fig-accent-20': #cd91b2,
  // 'fig-accent-10': #f1aad1,
  // BLACK
  'black': #242733,
  'black-50': #6a6b77,
  'black-20': #a0a1ae,
  'black-10': #cbccd6,
  // GRAY
  'gray': #e6e6e6,
  'gray-50': #f7f7f7,
  'gray-20': #fafafa,
  'gray-10': #fafafc,
  // WHITE
  'white': #fff,
  // BLUE
  'blue': #2550f5,
  'blue-50': #5677f7,
  'blue-20': #879ef9,
  'blue-10': #e8edfe,
  // PINK
  'pink': #d10070,
  'pink-50': #e880b7,
  'pink-20': #f6cce2,
  'pink-10': #fbeef5,
  // ORANGE
  'orange': #f38e4f,
  'orange-50': #f9c6a7,
  'orange-20': #fce3d4,
  'orange-10': #fdf4ee,
  // GREEN
  'green': #0db145,
  'green-50': #86d8a2,
  'green-20': #cfefda,
  'green-10': #e2f6e9,
  // RED
  'red': #e30303,
  'red-50': #f18181,
  'red-20': #f9cdcd,
  'red-10': #fce6e6
);

@function color($color) {
  $-value: map-get($colors, $color);

  @if $-value != null {
    @return $-value;
  } @else {
    @error "#{$color} cannot be found in the $colors map";
  }
}

:root {
  // Simple z-index layering system.
  // Avoid creating new layers, only create one when there is a new group of items.
  // For one off layering adjustments you can simply use `calc`:
  // `z-index: calc(var(--z-default) + 1)`
  //
  // `z-index: var(--z-default);`
  --z-background: -1;
  --z-default: 0;
  --z-overlay: 1;
  --z-ui: 10;
  --z-modal: 100;

  // Color variables.
  // If ever updating/adding colours make sure to also add to `tailwind.config.js`
  --c-fig-primary: #{color('fig-primary')};
  --c-fig-primary-500: #{color('fig-primary-500')};
  --c-fig-primary-10: #{color('fig-primary-10')};
  --c-fig-secondary: #{color('fig-secondary')};
  --c-fig-secondary-50: #{color('fig-secondary-50')};
  --c-fig-secondary-20: #{color('fig-secondary-20')};
  --c-fig-secondary-10: #{color('fig-secondary-10')};
  --c-fig-accent: #{color('fig-accent')};
  --c-fig-accent-500: #{color('fig-accent-500')};
  --c-fig-accent-20: #{color('fig-accent-20')};
  --c-fig-accent-10: #{color('fig-accent-10')};
  
  --c-black: #{color('black')};
  --c-black-50: #{color('black-50')};
  --c-black-20: #{color('black-20')};
  --c-black-10: #{color('black-10')};

  --c-gray: #{color('gray')};
  --c-gray-50: #{color('gray-50')};
  --c-gray-20: #{color('gray-20')};
  --c-gray-10: #{color('gray-10')};

  --c-white: #{color('white')};

  --c-blue: #{color('blue')};
  --c-blue-50: #{color('blue-50')};
  --c-blue-20: #{color('blue-20')};
  --c-blue-10: #{color('blue-10')};

  --c-pink: #{color('pink')};
  --c-pink-50: #{color('pink-50')};
  --c-pink-20: #{color('pink-20')};
  --c-pink-10: #{color('pink-10')};

  --c-orange: #{color('orange')};
  --c-orange-50: #{color('orange-50')};
  --c-orange-20: #{color('orange-20')};
  --c-orange-10: #{color('orange-10')};

  --c-green: #{color('green')};
  --c-green-50: #{color('green-50')};
  --c-green-20: #{color('green-20')};
  --c-green-10: #{color('green-10')};

  --c-red: #{color('red')};
  --c-red-50: #{color('red-50')};
  --c-red-20: #{color('red-20')};
  --c-red-10: #{color('red-10')};

  --c-transparent: hsla(0, 0%, 0%, 0);

  // Website color variables.
  // As above, update `tailwind.config.js` if adding or removing values
  --c-gray-100: #f3f4f6;
  --c-gray-200: #e5e7eb;
  --c-gray-300: #d1d5db;
  --c-gray-400: #9ca3af;
  --c-gray-500: #6b7280;
  --c-gray-600: #4b5563;
  --c-gray-700: #374151;
  --c-gray-800: #1f2937;
  --c-gray-900: #111827;

  --c-green-100: #dcfce7;
  --c-green-200: #bbf7d0;
  --c-green-300: #86efac;
  --c-green-400: #4ade80;
  --c-green-500: #22c55e;
  --c-green-600: #16a34a;
  --c-green-700: #15803d;
  --c-green-800: #166534;
  --c-green-900: #14532d;

  --c-indigo-50: #f6fbfd;
  --c-indigo-100: #e8f7fd;
  --c-indigo-200: #c5e6fb;
  --c-indigo-300: #9ecefb;
  --c-indigo-400: #69a2fb;
  --c-indigo-500: #3874fa;
  --c-indigo-600: #2550f5;
  --c-indigo-700: #203fe0;
  --c-indigo-800: #1a31af;
  --c-indigo-900: #152887;

  --c-yellow-50: #fefce8;
  --c-yellow-100: #fef9c3;
  --c-yellow-200: #fef08a;
  --c-yellow-300: #fde047;
  --c-yellow-400: #facc15;
  --c-yellow-500: #eab308;
  --c-yellow-600: #ca8a04;
  --c-yellow-700: #a16207;
  --c-yellow-800: #854d0e;
  --c-yellow-900: #713f12;
}
