/* columns */

.pg-columns {
    display: flex;
    flex-direction: column;
  }
  
  .pg-columns > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse));
  }
  
  @media (min-width: 1024px) {
  
    .pg-columns {
      flex-direction: row;
    }
  
    .pg-columns > :not([hidden]) ~ :not([hidden]) {
      --tw-space-x-reverse: 0;
      margin-right: calc(1rem * var(--tw-space-x-reverse));
      margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
      --tw-space-y-reverse: 0;
      margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
      margin-bottom: calc(0px * var(--tw-space-y-reverse));
    }
  
    .pg-columns-reversed {
      flex-direction: row-reverse;
    }
  }
  
  .pg-column {
    flex: 1 1 0%;
  }
  
  .pg-column-one-quarter {
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: 25%;
  }
  
  .pg-column-one-third {
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: 33.333333%;
  }
  
  .pg-align-items-center {
    align-items: center;
  }