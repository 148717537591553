@use "../../base/styles/layout/gf-layout.scss" as *;
@use "../../base/styles/components/gf-button.scss" as *;
@use "../../base/styles/base/gf-base.scss" as *;

.gf-help {
    @extend .gf-container--medium;
    display: flex;
    flex-direction: column;
    gap: 2.4rem;

    &--header {
        display: flex;
        flex-direction: column;
        gap: 1.2rem;
    }

    &--articles {
        display: flex;
        flex-direction: column;
        gap: 1.2rem;

        &--list {
            display: flex;
            flex-direction: column;
            gap: 1.2rem;
        }
    }

    &--actions {
        display: flex;
        gap: 1.2rem;
        justify-content: center;
        align-items: center;

        &--item {
            @extend .gf-button--primary;
            @extend .gf-full-width;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: .8rem;

            &--icon {
                width: 4rem;
                height: 4rem;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    &--footer {
        text-align: center;
    }
}