@use '../utilities/mixins';
.beta {
  position: relative;

  &::after {
    color: var(--c-fig-secondary);
    content: 'Beta';
    display: block;
    font-size: 1rem;
    font-weight: normal;
    left: 100%;
    position: absolute;
    top: -35%;
  }

  &__white {
    &::after {
      color: var(--c-white);
    }
  }
}
