// TODO:
// This is copied from _gf-button.scss
// Numbers need to be adjusted for inputs

@use "../utilities/_gf-utilities.scss" as *;
@use "../base/_gf-typography.scss" as *;

/*=============================================
=            Go Fig Input System            =
=============================================*/

$input-variants: (
    "text": (
        color: none,
        background: var(--gf-generic-white),
        border: 1px solid var(--gf-neutral-200),
        hover-effects: none
    ),
);

$input-sizes: (
    "small": (
        height: 3.2rem,
        padding: .8rem 1.2rem,
        icon-size: 1.6rem,
        font: "gf-paragraph--small"
    ),
    "medium": (
        height: 3.6rem,
        padding: 1.2rem 1.6rem,
        icon-size: 2rem,
        font: "gf-paragraph--medium"
    ),
    "large": (
        height: 4.4rem,
        padding: 2rem,
        icon-size: 2.4rem,
        font: "gf-paragraph--large"
    )
);

// Mixins -------------------------------------

@mixin input-variant($variant) {
    $config: map-get($input-variants, $variant);
    $effects: map-get($config, "hover-effects");
    color: map-get($config, "color");
    background-color: map-get($config, "background");
    border: map-get($config, "border");

    @if $effects != "none" {
        @if type-of($effects) != "list" {
            $effects: ($effects,);
        }
        @each $effect in $effects {
            @include gf-hover($effect);
        }
    }
}

@mixin input-size($size) {
    $config: map-get($input-sizes, $size);
    @extend .#{map-get($config, "font")};
    height: map-get($config, "height");
    padding: map-get($config, "padding");

    & + i, & + svg {
        width: map-get($config, "icon-size");
        height: map-get($config, "icon-size");
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

// Base input styles -------------------------

%gf-input-base {
    @include gf-border-radius("medium");
    width: 100%;
    outline: none;
    border-radius: .8rem;
    transition: all 0.2s ease-in-out;

    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }

    &::placeholder {
        color: var(--gf-neutral-400);
    }
}

// Input classes -----------------------------

.gf-input {
    $default-size: "medium";

    @extend %gf-input-base;
    @include input-variant("text");
    @include input-size($default-size);

    @each $variant, $variant-config in $input-variants {
        &--#{$variant} {
            @extend %gf-input-base;
            @include input-variant($variant);
            @include input-size($default-size);
        }

        @each $size, $size-config in $input-sizes {
            &--#{$variant}--#{$size} {
                @extend %gf-input-base;
                @include input-variant($variant);
                @include input-size($size);
            }
        }
    }
}

// Used in "apply to all" checkboxes in table settings
.gf-input--label--checkbox {
    @extend .gf-paragraph--small;
    margin-top: .4rem;
    color: var(--gf-neutral-500);
    display: flex;
    gap: .8rem;
}