@use "../base/gf-typography" as *;
@use "../components/gf-link" as *;
@use "../utilities/gf-utilities" as *;

%gf-card-base {
    @include gf-padding("xxlarge");
    @include gf-border-radius("large");
    @include gf-drop-shadow("small");
    background-color: var(--gf-generic-white);
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

.gf-card {
    @extend %gf-card-base;

    &--header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &--title {
            display: flex;
            flex-direction: column;
        }

        &--actions {
            display: flex;
            align-items: center;
            gap: 1.2rem;
        }
    }

    // &--body {
    //     @extend .gf-paragraph--small;
    //     color: var(--gf-neutral-500);
    // }

    &--footer {
        min-height: 2.4rem;
        display: flex;
        justify-content: start;

        &--cta {
            @include gf-hover("fade");
            @extend .gf-label--medium;
            @extend .gf-semibold;
            color: var(--gf-primary-500);
            display: flex;
            justify-content: space-between;
            flex: 1;

            &--text {
                display: flex;
                align-items: center;
                justify-content: start;
                gap: .8rem;

                >span {
                    @extend .gf-link;
                }
            }

            &--icon {
                >img {
                    width: 2.4rem;
                    height: 2.4rem;
                }
            }
        }
    }

    &--icon {
        width: 4rem;
        height: 4rem;
        border-radius: 50%;
        background-color: var(--gf-primary-50);
        color: var(--gf-primary-500);
        display: flex;
        align-items: center;
        justify-content: center;

        >img {
            width: 2rem;
            height: 2rem;
        }
    }
}

.gf-card--transparent {
    @extend %gf-card-base;
    @include gf-drop-shadow("none");
    background-color: transparent;
}