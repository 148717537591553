@use "../../base/styles/base/gf-typography";
@use "../../base/styles/layout/gf-layout";
@use "../../base/styles/utilities/gf-utilities" as *;

.gf-home {
    @extend .gf-container--full-width;

    &--features {
        max-height: calc(100vh - 4.8rem); // Leaves space for padding
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 2rem;

        .gf-card--transparent {
            padding-top: .4rem;
            padding-bottom: 0;
        }

        &--summary {
            min-height: 0; // Allow flex item to shrink
            flex: 1 1 auto; // Take remaining space + allow shrinking

            .gf-card--header--actions {
                span {
                    @extend .gf-semibold;
                    opacity: 0;
                    transform: translateX(10px);
                    transition: all .2s ease-out;

                    &.show {
                        opacity: 1;
                        transform: translateX(0);
                    }
                }

                // AI sparkle icon in summary card
                .gf-card--icon {
                    @include gf-hover("fade");
                    cursor: pointer;
                    position: relative;
                    transform: translateY(0);
                    transition: transform 0.2s ease;
                    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1),
                                0 2px 4px rgba(0, 0, 0, 0.06);

                    &:hover {
                        transform: translateY(-2px);
                        box-shadow: 0 6px 8px rgba(0, 0, 0, 0.12),
                                    0 3px 6px rgba(0, 0, 0, 0.08);
                    }

                    &:active {
                        transform: translateY(1px);
                        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08),
                                    0 1px 2px rgba(0, 0, 0, 0.04);
                    }

                    &.htmx-request img {
                        opacity: 0.5;
                        @include gf-spin("fast", "linear");
                    }
                }
                .animate-pulse {
                    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
                }
                
                @keyframes pulse {
                    0%, 100% {
                        opacity: 1;
                    }
                    50% {
                        opacity: .5;
                    }
                }
            }

            &--content--text {
                margin-right: 1.2rem;

                span {
                    @extend .gf-label--xsmall--muted;
                    display: block;
                    margin-bottom: 1.6rem;
                }
            }

            .gf-card--body {
                height: 100%;
                overflow-y: auto;
                position: relative; // For absolute positioning of loading ellipsis

                >p {
                    margin-bottom: 1.2rem;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                .gf-loading--ellipsis {
                    background: var(--gf-generic-white);
                }
            }

            // &--kpi {
            //     margin-top: .8rem;
            //     display: flex;
            //     flex-direction: column;
            //     justify-content: end;
            //     flex: 1;

            //     >span {
            //         @extend .gf-label--xsmall;
            //         color: var(--gf-neutral-500);
            //         display: flex;
            //         justify-content: center;
            //     }
            // }
        }

        &--projects {
            display: flex;
            max-height: calc(50% - 2rem);
            flex: 0 1 auto; // Don't grow beyond content size

            .gf-card--body {
                overflow-y: auto;
                display: flex;


                .gf-home--features--projects--list {
                    height: 100%;
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: start;
                    align-items: start;
                    gap: .8rem;
                }
            }

        }
    }

    &--dashboard {
        max-width: 70%;
        height: 100%;
        max-height: calc(100vh - 4.8rem); // Leaves space for padding
        flex: 2;

        &--pinned {
            height: 100%;
        }

        &--empty {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }
}