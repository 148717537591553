@use "../base/_gf-typography" as *;
@use "../utilities/_gf-utilities.scss" as *;

/*=============================================
=               Go Fig Messages              =
=============================================*/

$ribbon-width: 1.4rem;
$message-height: 2.8rem;

// Base styles

%gf-messages-base {
    position: absolute;
    top: 1.2rem;
    display: flex;
    flex-direction: column;
    gap: .8rem;
    z-index: 100;
}

%gf-message-base {
    position: relative;
    width: fit-content;
    height: $message-height;
    max-height: $message-height;
    padding: 0 2rem;
    color: var(--gf-neutral-50);
    background-color: var(--gf-sky-500);
    display: flex;
    align-items: center;

    >span {
        @extend .gf-overline--large;
        white-space: nowrap;
    }

    .gf-message--ribbon {
        position: absolute;
        height: 0;
        width: 0;
        display: flex;
        flex-direction: column;
    }
}

// Mixin for placement and animation variants

@mixin message-placement($placement, $animated: true) {
    $side: if($placement == "left", "left", "right");
    $opposite: if($placement == "left", "right", "left");

    justify-content: flex-#{if($placement == "left", "start", "end")};

    @if $animated {
        #{$side}: 0;
        // NOTE:
        // Can't include both animation mixins - overrides the animation property
        // @include gf-slide-in($placement, "fast", "ease-out");
        // @include gf-fade("out", "slow", "ease-out");
        animation:
            slide-#{$placement} .5s ease-out forwards,
            fade-out 3s ease-out 3s forwards;
    }

    .gf-message--ribbon {
        #{$opposite}: calc(-1 * (#{$ribbon-width} - 0rem));
        border-top: calc($message-height / 2) solid var(--gf-sky-500);
        border-bottom: calc($message-height / 2) solid var(--gf-sky-500);

        @if $placement == "left" {
            border-right: $ribbon-width solid transparent;
            align-items: flex-start;
        } @else {
            border-left: $ribbon-width solid transparent;
            align-items: flex-end;
        }
    }
}

// Final classes

.gf-messages {
    &--left {
        @extend %gf-messages-base;
        @include message-placement("left", true);
    }

    &--right {
        @extend %gf-messages-base;
        @include message-placement("right", true);
    }
}

.gf-message {
    @extend %gf-message-base;

    // Placement variants for stand alone messages - no animation

    &--left {
        @extend %gf-message-base;
        @include message-placement("left", false);
        margin: .8rem 0;
        margin-right: auto;
    }

    &--right {
        @extend %gf-message-base;
        @include message-placement("right", false);
        margin: .8rem 0;
        margin-left: auto;
    }
}