.integration {
  &__create-flow {
    display: flex;
    flex-flow: column;
    gap: 1.6rem;
  }

  &__file-upload {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    justify-content: center;

    &.inactive {
      opacity: 0.5;
    }
  }

  &__file-progress {
    position: relative;
    display: flex;
    justify-content: center;

    svg {
      stroke: var(--c-fig-secondary);
      transform: rotate(-90deg);
      stroke-dasharray: 220;
      stroke-dashoffset: 220;
    }

    h4 {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &.done {
      h4,
      svg {
        display: none;
      }
    }
  }

  &__file-validate {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    gap: 1.6rem;

    &.inactive {
      opacity: 0.5;
    }
  }
}

.page-header {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 9.6rem;
  justify-content: center;
  min-height: 9.6rem;
  overflow: hidden;
  // padding: 1.6rem;

  &__title {
    display: block;
    line-height: initial;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
}

.guide {
  background-color: var(--c-white);
  border-left: 1px solid var(--c-gray);
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  max-width: 40vw;
  min-width: 30em;
  overflow-y: auto;
  padding: 0.8rem 1.6rem;
  width: 100%;

  &__h1 {
    margin-top: 0.75em;
    margin-bottom: 0.75em;
  }

  &__h2 {
    font-weight: 500;
    margin-top: 0.675em;
    margin-bottom: 0.675em;
  }

  &__p,
  &__img {
    margin-top: 0.375em;
    margin-bottom: 0.375em;
  }

  &__img {
    &--s {
      width: 16em;
    }
  }

  &__highlight {
    border-radius: 4px;
    color: var(--c-red);
    border: 1px solid var(--c-gray);
    background-color: var(--c-gray-50);
    padding: 0.25em 0.5em;
  }
}
