@use "../base/_gf-typography.scss" as *;
@use "_gf-button.scss" as *;

/*=============================================
=                                             =
=          Go Fig Drag & Drop Styles          =
=                                             =
=          - Dashboards detail page           =
=          - Workflows detail page            =
=                                             =
=============================================*/

// TODO:
// Implement styles for header dropdown menus
// e.g. the "more" dropdown

// Current class names:
// `list__item list__item--interactive w-full`

// Header + canvas styles ---------------------

.gf-dnd {
    width: 100%;

    &--header {
        width: 100%;
        height: 6.8rem;
        padding: 1.2rem 2.4rem;
        background-color: var(--gf-neutral-50);
        border-bottom: 1px solid var(--gf-neutral-200);
        border-radius: 0 0 .8rem .8rem;
        display: flex;
        align-items: center;
        gap: 1.6rem;
        flex-shrink: 0;

        &--title {
            display: flex;
            align-items: center;
            gap: 1.2rem;
            flex: 1;

            .input__inline {
                background-color: transparent;
                border-radius: .4rem;

                &:focus {
                    color: var(--gf-neutral-500);
                }
            }
        }
    }

    &--content {
        width: 100%;
        height: 100%;
        overflow: hidden;
        display: flex;
        flex: 1;

        &--canvas {
            // Corresponds to `.dashboard`
            position: relative;
            width: 100%;
            height: calc(100vh - 6.8rem); // 6.8rem is the height of the header
            overflow: auto;
            flex: 1 1 0%;
        }
    }

    // Identical to `.form--no-layout`
    >form {
        color: red;
        align-items: inherit;
        display: inherit;
        flex-flow: inherit;
        gap: inherit;
    }

    // What is this included in main .dashboard style?
    // & + .dnd-sidebar {
    //     .dnd-sidebar__node {
    //       min-height: auto;
    //     }
    // }
}

// Sidebar styles -----------------------------


.gf-dnd--sidebar {
    width: 28rem;
    height: calc(100vh - 6.8rem);
    background-color: var(--gf-neutral-50);
    border-left: 1px solid var(--gf-neutral-200);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    z-index: var(--z-default);

    &--header {
        padding: 1.2rem;
        display: flex;
        justify-content: space-between;
        gap: 1.2rem;

        // Import and save buttons in workflows sidebar
        &--item {
            @extend .gf-button--neutral;
            @extend .gf-label--large;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 1.2rem;
            flex: 1;
            cursor: grab;
        }
    }

    &--category {
        padding: 1.2rem 2rem;

        &--title {
            @extend .gf-semibold;
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;

            // Arrow icon for collapsing
            &::after {
                content: '\f078';
                display: inline;
                font-family: 'Font Awesome 5 Pro';
                font-weight: 900;
                margin-right: .8rem;

                &.active {
                    content: '\f077';
                }
            }
        }

        &--items {
            padding-top: 1.2rem;
            display: flex;
            flex-direction: column;
            gap: 1.2rem;

            .gf-dnd--sidebar--category--item {
                // height: 3.2rem;
                padding: .8rem;
                color: var(--gf-neutral-600);
                display: flex;
                align-items: center;
                gap: 1.2rem;
                cursor: grab;

                &--icon {
                    @extend .gf-dnd--icon;
                }

                &--label {
                    flex: 1;

                    &--name {
                        @extend .gf-label--medium;
                        @extend .gf-semibold;
                    }

                    &--description {
                        @extend .gf-paragraph--xsmall;
                        color: var(--gf-neutral-500);
                        margin-top: .4rem;
                    }
                }

                &:hover {
                    background: var(--gf-neutral-100);
                    border-radius: 4px;
                }
            }
        }

        .collapsable {
            flex: 1;
            flex-direction: column;
            max-height: 0;
            overflow: hidden;
        }
    }
}

.gf-dnd--icon {
    width: 4rem;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;

    >i, svg {
        width: 1.6rem;
        color: var(--gf-primary-400);
    }
}