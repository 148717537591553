@use "../utilities/_gf-utilities.scss" as *;

/*=============================================
=            Go Fig Button System            =
=============================================*/

$button-variants: (
    "primary": (
        color: var(--gf-generic-white),
        background: var(--gf-primary-500),
        border: none,
        hover-effects: ("shadow", "fade")
    ),
    "neutral": (
        color: var(--gf-neutral-500),
        background: var(--gf-neutral-50),
        border: 1px solid var(--gf-neutral-200),
        hover-effects: ("shadow")
    ),
    "destructive": (
        color: var(--gf-generic-white),
        background: var(--gf-destructive-600),
        border: none,
        hover-effects: ("shadow", "fade")
    )
);

$button-sizes: (
    "small": (
        height: 3.2rem,
        padding: .6rem 1.2rem,
        icon-size: 1.2rem,
        font: "gf-label--small",
    ),
    "medium": (
        height: 3.6rem,
        padding: .8rem 1.6rem,
        icon-size: 1.6rem,
        font: "gf-label--medium",
    ),
    "large": (
        height: 4rem,
        padding: 1rem 2rem,
        icon-size: 1.6rem,
        font: "gf-label--large",

    )
);

// Mixins -------------------------------------

@mixin button-variant($variant) {
    $config: map-get($button-variants, $variant);
    $effects: map-get($config, "hover-effects");
    @if type-of($effects) != "list" {
        $effects: ($effects,);  // Convert single hover effect to list
    }
    color: map-get($config, "color");
    background-color: map-get($config, "background");
    border: map-get($config, "border");


    @each $effect in $effects {
        @include gf-hover($effect);
    }
}

@mixin button-size($size) {
    $config: map-get($button-sizes, $size);
    @extend .#{map-get($config, "font")};
    height: map-get($config, "height");
    padding: map-get($config, "padding");

    i, svg {
        width: map-get($config, "icon-size");
        height: map-get($config, "icon-size");
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

// Base button styles -------------------------

%gf-button-base {
    @include gf-border-radius("medium");
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease-in-out;
    gap: .8rem;
    cursor: pointer;

    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
}

// Button classes -----------------------------

// Usage:
// <button class="gf-button--primary"> - defaults to medium size
// <button class="gf-button--neutral"> - defaults to medium size
// <button class="gf-button--destructive"> - defaults to medium size
// <button class="gf-button--primary--small">
// <button class="gf-button--neutral--medium">
// <button class="gf-button--destructive--large">

.gf-button {
    $default-size: "medium";

    // Base class (primary-medium)
    @extend %gf-button-base;
    @include button-variant("primary");
    @include button-size($default-size);

    // Generate variant-only classes (defaults to medium)
    @each $variant, $variant-config in $button-variants {
        &--#{$variant} {
            @extend %gf-button-base;
            @include button-variant($variant);
            @include button-size($default-size);
        }

        // Generate specific variant-size combinations
        @each $size, $size-config in $button-sizes {
            &--#{$variant}--#{$size} {
                @extend %gf-button-base;
                @include button-variant($variant);
                @include button-size($size);
            }
        }
    }
}

// Special buttons ----------------------------

.gf-pill {
    @extend %gf-button-base;
    @include button-variant("primary");
    @include gf-border-radius("xxlarge");
    @include button-size("small");
}