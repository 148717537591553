@use '../utilities/mixins';

$card-colors: (
  'primary': var(--c-fig-secondary),
  'green': var(--c-green),
  'pink': var(--c-pink),
  'warning': var(--c-orange),
  'tertiary': var(--c-black),
) !default;

.card {
  --card-color: var(--c-gray);

  padding: 3.2rem;

  background: var(--c-white);
  border-radius: 10px;
  border: 1px solid var(--card-color);
  display: block;
  position: relative;
  z-index: var(--z-default);
  overflow: auto;

  @include mixins.shadow;

  &--inline {
    display: inline-flex;
  }

  &--outline {
    border: 1px dashed var(--card-color);
    background-color: var(--c-transparent);
  }

  &--square {
    align-items: center;
    color: var(--c-black-50);
    display: flex;
    flex-flow: column;
    height: 15rem;
    justify-content: center;
    text-align: center;
    width: 15rem;
    gap: 1.6rem;

    .fas {
      display: block;
    }
  }

  &[href]:hover {
    opacity: 0.5;
  }

  // Color variant generation
  @each $color, $value in $card-colors {
    &--#{$color} {
      --card-color: #{$value};

      @include mixins.fa-icon {
        color: var(--card-color);
      }
    }
  }

  // Layout
  &--center {
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
  }

  &--flex {
    display: flex;
  }

  // Sizes
  &--sm {
    padding: 3.2rem;
  }

  &--none {
    padding: 0;
  }

  &--modal {
    border: none;
    display: flex;
    flex: 1 1 0%;
    overflow: hidden;
  }

  &__help {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.8rem;

    &--bottom {
      top: initial;
      bottom: 0;
      width: 100%;
      padding-bottom: 0.4rem;
    }
  }
}
