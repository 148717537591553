.gf-modal--share {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;

    &--content {
        display: flex;
        flex-direction: column;
        gap: 1.2rem;
    }
}